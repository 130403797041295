import { Routes, Route } from 'react-router-dom';

// Import Routes
import App from './App';
import Auth from './Auth';
import ChallengeDetails from './pages/ChallengeDetails';
import FAQs from './pages/FAQs';
import SignOut from './pages/SignOut';
import Registered from './pages/Registered';
import Draft from './pages/Draft';
import DraftAdmin from './pages/DraftAdmin';

function RouteFile() {
    return (
        <Routes>
            <Route path='/' element={<App />} />
            <Route path='/auth' element={<Auth />} />
            <Route path='/details' element={<ChallengeDetails />} />
            <Route path='/faq' element={<FAQs />} />
            <Route path='/signout' element={<SignOut />}  />
            <Route path='/contestants' element={<Registered />} />
            <Route path='/draft' element={<Draft />} />
            <Route path='/draft/admin' element={<DraftAdmin />} />
        </Routes>
    )
}

export default RouteFile;