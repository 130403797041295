import { useEffect, useState } from 'react';
import { auth, functions } from './FirebaseConfig';
import {
  GoogleAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithRedirect,
  User,
  getRedirectResult,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import Loader from './Loader';
import ReactiveBGTexture from './assets/ReactiveBGTexture';

import challengeLogo from './assets/Logo.svg';
import Logo_Okta from './assets/oktaLogo.svg';
import Logo_Google from './assets/googleLogo.svg';
import Logo_Phone from './assets/symbols/phone.svg';

// Global declarations
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: any; // Adjust type as needed
  }
  interface CheckRegistrationResponse {
    registered: boolean;
    data?: Record<string, any>; // Adjust based on the actual data structure
  }
}

// Providers
const googleProvider = new GoogleAuthProvider();
const oktaProvider = new OAuthProvider('oidc.elevation-church-okta');

function Auth() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentAuthUser, setCurrentAuthUser] = useState<User | null>(null);
  const [isRegistered, setIsRegistered] = useState<boolean | null>(null);
  const [otpPhoneNumber, setOTPPhoneNumber] = useState('');
  const [OTP, setOTP] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [sfReturn, setSFReturn] = useState<any>(null);

  const [inputsVisible, setInputsVisible] = useState(false);

  const [displayName, setDisplayName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [staff, setStaff] = useState(false);
  const [spouse, setSpouse] = useState(false);
  const [sfHousehold, setSFHousehold] = useState('');
  const [shirtSize, setShirtSize] = useState('');
  const [profilePic, setProfilePic] = useState('');

  // Check window size to determine if user is on mobile device
  const [isMobile, setIsMobile] = useState(false);
  const [isVertical, setIsVertical] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1320); // Previous 1024
      setIsVertical(window.innerWidth > window.innerHeight);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check registration status through Firebase Function
  const handleRegistrationCheck = async (user: any) => {
    try {
      const registrationCheckFn = httpsCallable<
        void,
        CheckRegistrationResponse
      >(functions, 'registrationCheck');
      const result = await registrationCheckFn();
      if (result.data.registered) {
        setIsRegistered(true);
        setIsLoading(false);
        return true;
      } else {
        setIsRegistered(false);
        return false;
      }
    } catch (error) {
      console.error('Error checking registration:', error);
      setIsRegistered(false);
      return false;
    }
  };

  // Function to handle Salesforce check
  const handleSalesforceCheck = async (user: User) => {
    try {
      const checkSalesforceFn = httpsCallable(functions, 'salesforceCheck');
      const result = await checkSalesforceFn({
        email: user.email || null,
        phone: user.phoneNumber || null,
      });

      setSFReturn(
        result.data as {
          displayName: string;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          staff: boolean;
          spouse: boolean;
          sfHousehold: string;
          shirtSize: string;
        }
      );

      console.log('Data received from Salesforce:', result.data);

      handleInputPopulation(result.data);
    } catch (error) {
      console.error('Salesforce Check Error:', error);
    }
  };

  const handleInputPopulation = (salesforceData: any) => {
    let tempPhone = currentAuthUser?.phoneNumber || salesforceData?.phone || '';

    if (tempPhone.length < 12) {
      tempPhone = '+1' + tempPhone;
    }

    setDisplayName(currentAuthUser?.displayName || salesforceData?.displayName);
    setFirstName(
      salesforceData?.firstName || currentAuthUser?.displayName?.split(' ')[0]
    );
    setLastName(
      salesforceData?.lastName || currentAuthUser?.displayName?.split(' ')[1]
    );
    setEmail(currentAuthUser?.email || salesforceData?.email || '');
    setPhone(tempPhone);
    setStaff(salesforceData?.staff);
    setSpouse(salesforceData?.spouse);
    setSFHousehold(salesforceData?.sfHousehold);
    setShirtSize(salesforceData?.shirtSize || '');
    setProfilePic(currentAuthUser?.photoURL || '');

    // setInputsVisible(true);
    setIsLoading(false);
    console.log(displayName);
  };

  const initializeAuthFlow = async (user: any) => {
    setIsLoading(true);

    const registered = await handleRegistrationCheck(user);
    if (registered) {
      window.location.replace('/');
    } else {
      await handleSalesforceCheck(user);
    }
  };

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log('User signed in:', user);
        setCurrentAuthUser(user);
        await initializeAuthFlow(user);
      } else {
        console.log('No user signed in.');
        setCurrentAuthUser(null);
        setIsRegistered(false);
        setIsLoading(false);
      }
    });

    // Handle redirect sign-in result
    getRedirectResult(auth)
      .then(async (result) => {
        if (result) {
          console.log('Redirect result detected:', result);
          if (result.user) {
            console.log('Redirect user:', result.user);
            setCurrentAuthUser(result.user);
            await initializeAuthFlow(result.user);
          } else {
            console.log('No user found in redirect result.');
            setCurrentAuthUser(null);
            setIsRegistered(false);
            setIsLoading(false);
          }
        } else {
          console.log('No redirect result available.');
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Redirect Error:', error);
        setIsLoading(false);
      });

    return () => unsubscribe();
  }, []);

  // Function to handle Google Sign-In
  const handleGoogleSignIn = async () => {
    setIsLoading(true);

    try {
      await signInWithRedirect(auth, googleProvider).then(async (result) => {
        console.log(result);
      });
    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  // Function to handle Okta Sign-In
  const handleOktaSignIn = async () => {
    setIsLoading(true);

    try {
      await signInWithRedirect(auth, oktaProvider);
    } catch (error) {
      console.error('Okta Sign-In Error:', error);
    }
  };

  // Function to generate and reset Recaptcha
  const generateRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      { size: 'invisible' }
    );
  };

  // Function to handle Phone Sign-In
  const handlePhoneSignIn = async () => {
    setIsLoading(true);

    const phoneFormatted = otpPhoneNumber.replace(/[^+\d]/g, '');
    // console.log(phoneFormatted);

    if (phoneFormatted.length >= 12) {
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;

      try {
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          phoneFormatted,
          appVerifier
        );
        window.confirmationResult = confirmationResult;
        alert('OTP sent to your phone.');
        setCodeSent(true);
        setIsLoading(false);
      } catch (error) {
        console.error('Phone Sign-In Error:', error);
        setIsLoading(false);
      }
    } else {
      alert('Invalid Phone Number.');
      setIsLoading(false);
    }
  };

  // Function to verify OTP
  const verifyOTP = async () => {
    setIsLoading(true);

    if (OTP.length === 6) {
      try {
        const confirmationResult = window.confirmationResult;
        const result = await confirmationResult.confirm(OTP);
        const user = result.user;

        await handleSalesforceCheck(user);
      } catch (error) {
        console.error('OTP Verification Error:', error);
        setIsLoading(false);
      }
    } else {
      alert('Invalid OTP.');
      setIsLoading(false);
    }
  };

  // Function to handle Registration Submission
  const handleSubmitRegistration = async () => {
    setIsLoading(true);

    if (!currentAuthUser || !sfReturn) {
      alert('Authentication or Salesforce data is missing.');
      return;
    }

    try {
      // Prepare data to send to registerUser function
      const registerUser = httpsCallable(functions, 'registerUser');
      await registerUser({
        userId: currentAuthUser.uid,
        displayName,
        firstName,
        lastName,
        email,
        phone,
        staff,
        spouse,
        sfHousehold,
        shirtSize,
        profilePic,
      });

      setInputsVisible(false);
      setIsRegistered(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Registration Error:', error);
      alert('Failed to submit registration.');
      setIsLoading(false);
    }
  };

  // Function to handle Sign Out
  const handleSignOut = () => {
    auth.signOut();
  };

  return (
    <div className='App w-screen h-screen fixed bg-light dark:bg-dark'>
      <ReactiveBGTexture />
      <div className='w-screen h-screen overflow-y-auto flex flex-col justify-start md:justify-center items-center fixed top-0 left-0'>
        <img
          className='w-[70%] md:w-[50%] xl:w-[20%] max-w-2xl aspect-auto mt-8 mb-8 md:mt-0 md:mb-16'
          src={challengeLogo}
          alt='March Madness'
        />
        {!inputsVisible ? (
          <div className='w-[90%] max-w-4xl flex flex-col md:flex-row justify-center items-center mb-32 md:mb-0'>
            {!currentAuthUser ? (
              <>
                <div className='w-full h-full py-4 px-4 bg-c1 border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble mb-10 md:mr-10 md:mb-0'>
                  <h1 className='text-2xl text-dark font-inter font-bold italic uppercase mb-4'>
                    Sign In
                  </h1>
                  <h2 className='text-lg text-dark font-inter font-medium uppercase'>
                    Staff
                  </h2>
                  <div className='w-full flex justify-between gap-5 mb-4'>
                    <button
                      className='w-full h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-white'
                      onClick={handleOktaSignIn}
                    >
                      <img
                        className='w-[65%] h-[65%]'
                        src={Logo_Okta}
                        alt='Okta Sign In'
                      />
                    </button>
                    <button
                      className='w-full h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-white'
                      onClick={handleGoogleSignIn}
                    >
                      <img
                        className='w-[65%] h-[65%]'
                        src={Logo_Google}
                        alt='Google Sign In'
                      />
                    </button>
                  </div>
                  <h2 className='text-lg text-dark font-inter font-medium uppercase'>
                    Spouses
                  </h2>
                  <div className='w-full flex mb-6'>
                    <div className='w-full h-20 border-[1px] border-dark shadow-darkFull flex justify-start items-center gap-5 px-5 bg-white'>
                      <img
                        className='aspect-square h-[40%]'
                        src={Logo_Phone}
                        alt='Phone Sign In'
                      />
                      {!codeSent ? (
                        <input
                          className='w-full text-xl focus:outline-none placeholder:text-lightGray'
                          type='text'
                          placeholder='+1 (000) 000-0000'
                          value={otpPhoneNumber}
                          onChange={(e) => {
                            if (otpPhoneNumber === '') {
                              setOTPPhoneNumber('+1' + e.target.value);
                            } else if (otpPhoneNumber === '+1') {
                              setOTPPhoneNumber('');
                            } else {
                              setOTPPhoneNumber(e.target.value);
                            }
                          }}
                          onSubmit={handlePhoneSignIn}
                        ></input>
                      ) : (
                        <input
                          className='w-full text-xl focus:outline-none placeholder:text-lightGray'
                          type='text'
                          placeholder='Enter OTP Code'
                          value={OTP}
                          onChange={(e) => setOTP(e.target.value)}
                          onSubmit={verifyOTP}
                        ></input>
                      )}
                      <button
                        className='w-[12.5%] aspect-square'
                        onClick={() => {
                          if (!codeSent) {
                            handlePhoneSignIn();
                          } else {
                            verifyOTP();
                          }
                        }}
                      >
                        <svg
                          viewBox='0 0 500 500'
                          className='fill-current text-lightGray hover:text-black'
                        >
                          <path d='M0 0h500v500H0z' className='fill-none' />
                          <clipPath id='a'>
                            <path d='M0 0h500v500H0z' />
                          </clipPath>
                          <g clip-path='url(#a)'>
                            <path
                              d='M77.07 30.542c36.357 0 65.85-29.455 65.85-65.812s-29.493-65.811-65.85-65.811c-36.319 0-65.811 29.454-65.811 65.811 0 36.357 29.492 65.812 65.811 65.812Zm0-14.746c-28.229 0-51.041-22.837-51.041-51.066 0-28.229 22.812-51.066 51.041-51.066 28.229 0 51.066 22.837 51.066 51.066 0 28.229-22.837 51.066-51.066 51.066Zm31.388-51.104c0-1.684-.576-3.338-2.189-4.98L86.655-60.599c-1.14-1.139-2.308-1.791-3.943-1.791-3.16 0-5.377 2.643-5.377 5.594 0 1.588.686 3.068 1.854 4.17l8.712 8.23 4.471 3.285-9.061-.562H51.445c-3.348 0-6.246 2.833-6.246 6.365 0 3.541 2.898 6.365 6.246 6.365h31.866l9.074-.562-4.484 3.299-8.712 8.23c-1.168 1.102-1.854 2.62-1.854 4.208 0 2.964 2.217 5.556 5.377 5.556 1.635 0 2.803-.652 3.943-1.754l19.614-20.362c1.613-1.642 2.189-3.295 2.189-4.98Z'
                              transform='translate(-42.842 383.98) scale(3.79873)'
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <a
                    className='text-base text-dark font-inter font-medium'
                    href='https://staff.elevationchurch.org/support/tickets/new?category=Software&sub_category=Account%20Access&subject=March%20Madness%20eFit%20Support'
                    target='_blank'
                  >
                    Need help? Click here for support.
                  </a>
                </div>
                <div className='w-full h-full py-4 px-4 bg-lightGray border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble'>
                  <h1 className='text-2xl text-dark font-inter font-bold italic uppercase mb-4'>
                    About
                  </h1>
                  <div className='w-full flex flex-col justify-center items-center gap-5'>
                    <a
                      className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c3'
                      href='/details'
                      target='_blank'
                    >
                      <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                        <svg className='w-[50%] fill-c3' viewBox='0 0 500 500'>
                          <path d='M0 0h500v500H0z' className='fill-none' />
                          <path
                            d='M16.084-83.683c0 24.855 12.606 41.447 37.376 49.489 4.235 5.622 9.197 10.208 14.181 13.323V3.656H57.464c-8.925 0-14.108 5.473-14.108 14.107v11.255c0 3.738 2.944 6.476 6.595 6.476h60.488c3.651 0 6.594-2.738 6.594-6.476V17.763c0-8.634-5.182-14.107-14.107-14.107H92.749v-24.527c4.983-3.115 9.945-7.701 14.219-13.323 24.732-8.042 37.338-24.634 37.338-49.489 0-7.441-4.958-12.132-12.783-12.132h-11.399c-1.563-6.506-6.638-10.394-14.531-10.394H54.81c-7.867 0-12.981 3.835-14.544 10.394H28.867c-7.825 0-12.783 4.691-12.783 12.132Zm11.869 2.122c0-1.087.761-1.861 1.861-1.861h9.707v11.113c0 7.632 1.775 14.832 4.62 21.188-10.586-6.268-16.188-16.553-16.188-30.44Zm88.296 30.44c2.845-6.356 4.619-13.556 4.619-21.188v-11.113h9.708c1.1 0 1.861.774 1.861 1.861 0 13.887-5.602 24.143-16.188 30.44Z'
                            transform='translate(-32.969 374.759) scale(3.52851)'
                          />
                        </svg>
                      </div>
                      <h2 className='text-lg text-dark font-inter font-normal uppercase'>
                        Challenge Details
                      </h2>
                    </a>
                    <a
                      className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c2'
                      href='/faq'
                      target='_blank'
                    >
                      <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                        <svg className='w-[50%] fill-c2' viewBox="0 0 500 500">
                          <path d="M0 0h500v500H0z" className='fill-none'/>
                          <path
                            d="M56.805 35.994c2.685 0 4.794-1.215 7.982-4.071l23.457-20.864h41.242c18.778 0 29.981-11.344 29.981-29.943v-50.174c0-18.599-11.203-29.981-29.981-29.981H46.065c-18.778 0-29.981 11.355-29.981 29.981v50.174c0 18.626 11.787 29.943 29.105 29.943h5.369v17.997c0 4.243 2.383 6.938 6.247 6.938Zm29.413-67.471c-4.656 0-7.252-2.04-7.252-6.001v-.671c0-5.312 3.154-8.555 7.862-11.827 5.405-3.773 8.138-5.687 8.138-9.512 0-3.863-3.259-6.747-7.954-6.747-3.68 0-6.532 1.79-8.52 5.25l-.733 1.034c-1.342 1.81-3.214 2.823-5.718 2.823-3.213 0-5.799-2.388-5.799-5.708 0-1.29.26-2.449.697-3.626 2.295-6.374 10.1-11.318 21.32-11.318 12.241 0 22.093 6.698 22.093 17.46 0 7.241-3.631 10.987-10.341 15.314-3.958 2.554-6.459 4.745-6.897 7.93-.029.229-.058.541-.087.866-.38 2.746-3.01 4.733-6.809 4.733Zm-.06 20.701c-4.631 0-8.355-3.05-8.355-7.522 0-4.404 3.686-7.522 8.355-7.522 4.644 0 8.228 3.109 8.228 7.522 0 4.509-3.623 7.522-8.228 7.522Z"
                            transform="translate(-56.087 359.925) scale(3.48716)"
                          />
                        </svg>
                      </div>
                      <h2 className='text-lg text-dark font-inter font-normal uppercase'>
                        FAQ
                      </h2>
                    </a>
                    <div className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-darkGray'>
                      <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                        <svg
                          className='w-[50%] fill-darkGray'
                          viewBox="0 0 500 500"
                        >
                            <path d="M0 0h500v500H0z" className='fill-none'/>
                            <path
                            d="M27.848 21.915h83.274c7.373 0 11.802-3.572 11.802-9.476 0-16.126-20.785-38.257-53.432-38.257-32.609 0-53.408 22.131-53.408 38.257 0 5.904 4.442 9.476 11.764 9.476Zm41.67-58.8c14.089 0 25.607-12.324 25.607-28.117 0-15.29-11.616-27.498-25.607-27.498-13.953 0-25.582 12.36-25.582 27.574 0 15.717 11.531 28.041 25.582 28.041Z"
                            transform="translate(-53.735 404.229) scale(4.37004)"
                          />
                        </svg>
                      </div>
                      <h2 className='text-lg text-light font-inter font-normal'>
                        Sign In to view Contestants.
                      </h2>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='w-full h-full py-4 px-4 flex flex-col justify-between items-start bg-c1 border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble mb-10 md:mr-10 md:mb-0'>
                  <div className='w-full flex flex-col justify-center items-start'>
                    <h2 className='text-lg text-dark font-inter font-normal uppercase'>
                      Welcome,
                    </h2>
                    <h2 className='text-2xl text-dark font-inter font-bold uppercase mb-3'>
                      {displayName || currentAuthUser?.displayName}
                    </h2>
                    <h3 className='text-lg text-dark font-inter font-medium mb-3'>
                      Registration Status:{' '}
                      <span className='text-light'>
                        {isRegistered ? 'Registered' : 'NOT Registered'}
                      </span>
                    </h3>
                    {!isRegistered ? (
                      <button
                        className='h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-white px-5'
                        onClick={() => setInputsVisible(true)}
                      >
                        <h2 className='text-xl text-dark font-inter font-medium uppercase'>
                          Start Registration
                        </h2>
                      </button>
                    ) : null}
                  </div>
                  <a
                    className='text-base text-dark font-inter font-medium'
                    href='https://staff.elevationchurch.org/support/tickets/new?category=Software&sub_category=Account%20Access&subject=March%20Madness%20eFit%20Support'
                    target='_blank'
                  >
                    Need help? Click here for support.
                  </a>
                </div>
                <div className='w-full h-full py-4 px-4 bg-lightGray border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble'>
                  <h1 className='text-2xl text-dark font-inter font-bold italic uppercase mb-4'>
                    About
                  </h1>
                  <div className='w-full flex flex-col justify-center items-center gap-5'>
                    <a
                      className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c3'
                      href='/details'
                      target='_blank'
                    >
                      <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                        <svg className='w-[50%] fill-c3' viewBox='0 0 500 500'>
                          <path d='M0 0h500v500H0z' className='fill-none' />
                          <path
                            d='M16.084-83.683c0 24.855 12.606 41.447 37.376 49.489 4.235 5.622 9.197 10.208 14.181 13.323V3.656H57.464c-8.925 0-14.108 5.473-14.108 14.107v11.255c0 3.738 2.944 6.476 6.595 6.476h60.488c3.651 0 6.594-2.738 6.594-6.476V17.763c0-8.634-5.182-14.107-14.107-14.107H92.749v-24.527c4.983-3.115 9.945-7.701 14.219-13.323 24.732-8.042 37.338-24.634 37.338-49.489 0-7.441-4.958-12.132-12.783-12.132h-11.399c-1.563-6.506-6.638-10.394-14.531-10.394H54.81c-7.867 0-12.981 3.835-14.544 10.394H28.867c-7.825 0-12.783 4.691-12.783 12.132Zm11.869 2.122c0-1.087.761-1.861 1.861-1.861h9.707v11.113c0 7.632 1.775 14.832 4.62 21.188-10.586-6.268-16.188-16.553-16.188-30.44Zm88.296 30.44c2.845-6.356 4.619-13.556 4.619-21.188v-11.113h9.708c1.1 0 1.861.774 1.861 1.861 0 13.887-5.602 24.143-16.188 30.44Z'
                            transform='translate(-32.969 374.759) scale(3.52851)'
                          />
                        </svg>
                      </div>
                      <h2 className='text-lg text-dark font-inter font-normal uppercase'>
                        Challenge Details
                      </h2>
                    </a>
                    <a
                      className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c2'
                      href='/faq'
                      target='_blank'
                    >
                      <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                        <svg className='w-[50%] fill-c2' viewBox="0 0 500 500">
                          <path d="M0 0h500v500H0z" className='fill-none'/>
                          <path
                            d="M56.805 35.994c2.685 0 4.794-1.215 7.982-4.071l23.457-20.864h41.242c18.778 0 29.981-11.344 29.981-29.943v-50.174c0-18.599-11.203-29.981-29.981-29.981H46.065c-18.778 0-29.981 11.355-29.981 29.981v50.174c0 18.626 11.787 29.943 29.105 29.943h5.369v17.997c0 4.243 2.383 6.938 6.247 6.938Zm29.413-67.471c-4.656 0-7.252-2.04-7.252-6.001v-.671c0-5.312 3.154-8.555 7.862-11.827 5.405-3.773 8.138-5.687 8.138-9.512 0-3.863-3.259-6.747-7.954-6.747-3.68 0-6.532 1.79-8.52 5.25l-.733 1.034c-1.342 1.81-3.214 2.823-5.718 2.823-3.213 0-5.799-2.388-5.799-5.708 0-1.29.26-2.449.697-3.626 2.295-6.374 10.1-11.318 21.32-11.318 12.241 0 22.093 6.698 22.093 17.46 0 7.241-3.631 10.987-10.341 15.314-3.958 2.554-6.459 4.745-6.897 7.93-.029.229-.058.541-.087.866-.38 2.746-3.01 4.733-6.809 4.733Zm-.06 20.701c-4.631 0-8.355-3.05-8.355-7.522 0-4.404 3.686-7.522 8.355-7.522 4.644 0 8.228 3.109 8.228 7.522 0 4.509-3.623 7.522-8.228 7.522Z"
                            transform="translate(-56.087 359.925) scale(3.48716)"
                          />
                        </svg>
                      </div>
                      <h2 className='text-lg text-dark font-inter font-normal uppercase'>
                        FAQ
                      </h2>
                    </a>
                    <a
                      className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c1'
                      href='/contestants'
                    >
                      <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                        <svg
                          className='w-[50%] fill-c1'
                          viewBox="0 0 500 500"
                        >
                            <path d="M0 0h500v500H0z" className='fill-none'/>
                            <path
                            d="M27.848 21.915h83.274c7.373 0 11.802-3.572 11.802-9.476 0-16.126-20.785-38.257-53.432-38.257-32.609 0-53.408 22.131-53.408 38.257 0 5.904 4.442 9.476 11.764 9.476Zm41.67-58.8c14.089 0 25.607-12.324 25.607-28.117 0-15.29-11.616-27.498-25.607-27.498-13.953 0-25.582 12.36-25.582 27.574 0 15.717 11.531 28.041 25.582 28.041Z"
                            transform="translate(-53.735 404.229) scale(4.37004)"
                          />
                        </svg>
                      </div>
                      <h2 className='text-lg text-dark font-inter font-normal uppercase'>
                        Contestants
                      </h2>
                    </a>
                  </div>
                </div>
              </>
            )}
            <div id='recaptcha-container'></div>
          </div>
        ) : (
          <div className='w-[95%] max-w-4xl flex flex-col md:flex-row justify-center items-center mb-32 md:mb-0'>
            <div className='w-full h-full py-4 px-4 bg-c1 border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble'>
              <h1 className='text-2xl text-dark font-inter font-bold italic uppercase mb-4'>
                Register
              </h1>
              <div className='w-full flex flex-col justify-center items-end gap-5'>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5'>
                  <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                    <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                      Display Name
                    </h2>
                    <input
                      className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                      type='text'
                      placeholder='Chili Bean'
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                    ></input>
                  </div>
                  <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                    <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                      First Name
                    </h2>
                    <input
                      className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                      type='text'
                      placeholder='Chili'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></input>
                  </div>
                  <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                    <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                      Last Name
                    </h2>
                    <input
                      className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                      type='text'
                      placeholder='Bean'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></input>
                  </div>
                  <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                    <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                      Email
                    </h2>
                    <input
                      className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                      type='text'
                      placeholder='chili.bean@elevationchurch.org'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>
                  <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                    <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                      Phone
                    </h2>
                    <input
                      className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                      type='text'
                      placeholder='+1 (000) 000-0000'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></input>
                  </div>
                  <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                    <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                      Shirt Size
                    </h2>
                    <input
                      className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                      type='text'
                      placeholder='Medium'
                      value={shirtSize}
                      onChange={(e) => setShirtSize(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className='w-full flex flex-row justify-between items-end'>
                  <a
                    className='text-base text-dark font-inter font-medium'
                    href='https://staff.elevationchurch.org/support/tickets/new?category=Software&sub_category=Account%20Access&subject=March%20Madness%20eFit%20Support'
                    target='_blank'
                  >
                    Need help? Click here for support.
                  </a>
                  <button
                    className='h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-white px-5'
                    onClick={handleSubmitRegistration}
                  >
                    <h2 className='text-xl text-dark font-inter font-medium uppercase'>
                      Submit Registration
                    </h2>
                  </button>
                </div>
              </div>
            </div>
            <div id='recaptcha-container'></div>
          </div>
        )}
      </div>
      {isLoading ? <Loader /> : null}
    </div>
  );
}

export default Auth;
