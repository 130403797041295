import { useEffect, useState } from 'react';
import { auth, functions } from '../FirebaseConfig';
import { User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import Loader from '../Loader';
import ReactiveBGTexture from '../assets/ReactiveBGTexture';

import challengeLogo from '../assets/Logo.svg';

// Global declarations
declare global {
//   interface CheckRegistrationResponse {
//     registered: boolean;
//     data?: Record<string, any>; // Adjust based on the actual data structure
//   }
    interface GetUsersResponse {
        success: boolean;
        users?: Array<{
            uid: string;
            profilePic: string;
            firstName: string;
            lastName: string;
        }>;
    }
}

function Registered() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentAuthUser, setCurrentAuthUser] = useState<User | null>(null);
  const [users, setUsers] = useState<
    Array<{
        uid: string;
        profilePic: string;
        firstName: string;
        lastName: string;
    }>
    >([]);

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log('User signed in:', user);
          setCurrentAuthUser(user);
      } else {
        console.log('No user signed in.');
        setCurrentAuthUser(null);
        setIsLoading(false);
        window.location.replace('/auth');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleUserPopulation = async () => {
        setIsLoading(true);
        try {
            const getAllUsersFn = httpsCallable<void, GetUsersResponse>(functions, 'getAllUsers');
            const result = await getAllUsersFn();

            if (result.data.success && result.data.users) {
                setUsers(result.data.users);
            }
        } catch (error) {
            console.log('Error getting user profiles:', error);
        } finally {
            setIsLoading(false);
        }
    };

    handleUserPopulation();
}, [currentAuthUser]);

  return (
    <div className='App w-screen h-screen fixed bg-light dark:bg-dark'>
      <ReactiveBGTexture />
      <div className='w-screen h-screen flex flex-col justify-start items-center bg-transparent fixed top-0 left-0'>
        <div className='w-full h-[10vh] 2xl:h-[7vh] flex flex-row justify-center items-center p-3 border-b-[1px] border-lightGray dark:border-darkGray'>
            <img className='h-full' src={challengeLogo} alt="March Madness" />
        </div>
        <div className='w-full h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 overflow-y-auto overflow-x-hidden px-8 pr-12 gap-8 mt-8 mb-24 md:mb-8'>
            {users.map((user) => (
                <div key={user.uid} className='w-full h-32 flex flex-row justify-center items-center m-2 p-4 bg-lightGray border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull'>
                    {user.profilePic &&
                        <img
                            src={user.profilePic}
                            className='w-24 h-24 aspect-square bg-lightGray border-[1px] border-dark shadow-darkHalf mr-6'
                        />
                    }
                    <h1 className='uppercase text-center text-xl md:text-2xl 2xl:text-3xl text-dark font-inter font-black tracking-widest'>
                        {user.firstName} {user.lastName}
                    </h1>
                </div>
            ))}
        </div>
        {isLoading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default Registered;
