import React, { useEffect, useState } from "react";
import { db, functions } from "../FirebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  doc,
  getDoc
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { User } from "firebase/auth";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Loader from "../Loader";

dayjs.extend(utc);
dayjs.extend(timezone);

// Interface declarations
interface LogUserDataResponse {
  success: boolean;
  message?: string;
}

// Type declarations
type LogProps = {
  currentAuthUser: User | null;
};
type ComponentsData = {
  meat: number;
  armor: number;
  resistanceMins: number;
  resistanceMeps: number;
  cardioMins: number;
  cardioMeps: number;
  hydration: number;
}
// type ComponentKeys = "meat" | "armor" | "resistance" | "cardio" | "hydration";
type Entry = {
  name: string; // '01', '02', etc.
  active: boolean; // Is this day currently selected?
  today: boolean; // Is this the current day in the challenge?
  editable: boolean; // Is the user allowed to edit/log for this day?
  status: "completed" | "partial" | "incomplete" | "locked";
  // Instead of storing 0/1, we'll store the raw metric (minutes, grams, ounces, etc).
  components: ComponentsData;
  message: string;
};
type CompletedComponents = Record<string, 0 | 1>;

function Log({ currentAuthUser }: LogProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectedDateString, setSelectedDateString] = useState('');
  // const [logButtonLocked, setLoggedButtonLocked] = useState(true);

  const [entries, setEntries] = useState<Entry[]>([]);
  const [goals, setGoals] = useState({ proteinGoal: 0, hydrationGoal: 0});

  // Calculate which day of the challenge it is
  const EST = 'America/New_York';
  const today = dayjs().tz(EST);
  const challengeStartDate = dayjs('2025-01-14T00:00:00', 'YYYY-MM-DDTHH:mm:ss').tz(EST); // ADJUST FOR TESTING
  const dayOfYear = today.diff(challengeStartDate, 'day') + 1; // e.g. 1-based

  // Initial Calendar Data
  useEffect(() => {
    const getUserGoals = async () => {
      try {
        if (!currentAuthUser) return;

        const userRef = doc(db, 'users', currentAuthUser.uid);
        getDoc(userRef).then((snap) => {
          const data = snap.data() || {};
          if (!data.proteinGoal || !data.hydrationGoal) {
            console.error('No protein goal OR weight goal.')
          } else {
            setGoals({
              proteinGoal: data.proteinGoal,
              hydrationGoal: data.hydrationGoal,
            })
          }
        });

      } catch (error) {
        console.error('Error getting user goals:', error);
      }
    };

    const fetchCalendarData = async () => {
      try {
        if (!currentAuthUser) {
          console.error("User is not signed in.");
          window.location.replace('/auth');
          return;
        }

        // Query all 36 days
        const activityQuery = query(
          collection(db, "activity"),
          where("userId", "==", currentAuthUser.uid)
        );
        const snapshot = await getDocs(activityQuery);

        // Build out the 36 days
        const calendarData = Array(36)
          .fill(null)
          .map((_, index) => {
            const dayNumber = index + 1;
            const dayDoc = snapshot.docs.find(
              (doc) => doc.data().day === dayNumber
            );
            const rawComponents = dayDoc?.data()?.rawComponents || {};

            // Convert any missing components to 0
            const defaultComponents: ComponentsData = {
              meat: rawComponents.meat || 0,
              armor: rawComponents.armor || 0,
              resistanceMins: rawComponents.resistanceMins || 0,
              resistanceMeps: rawComponents.resistanceMeps || 0,
              cardioMins: rawComponents.cardioMins || 0,
              cardioMeps: rawComponents.cardioMeps || 0,
              hydration: rawComponents.hydration || 0,
            };

            /**
             * The doc may also have 'completedComponents', but for now we only care about raw input to display partial progress.
             * We can also derive 'completed', 'partial', etc. from completedComponents, if needed.
             *
             * For the status, maybe use old approach of counting how many are 1, or decide to handle partial vs complete in a more advanced way.
             * For now, reuse the 'completedComponents' if it exists.
             */
            let status: "completed" | "partial" | "incomplete" | "locked" =
              "locked";
            if (dayNumber <= dayOfYear) {
              const completedCompObj =
                dayDoc?.data()?.completedComponents || {};
              const completedCount = Object.values(
                completedCompObj as CompletedComponents
              ).filter((v: number) => v === 1).length;
              if (completedCount === 5) {
                status = "completed";
              } else if (completedCount > 0) {
                status = "partial";
              } else {
                status = "incomplete";
              }
            }

            if (dayNumber === dayOfYear) {
              const dateForSelectedDay = dayjs(challengeStartDate).add(dayNumber - 1, 'day').tz(EST);
              setSelectedDateString(dateForSelectedDay.format('MMMM D'));
            }

            return {
              name: dayNumber.toString().padStart(2, "0"),
              active: dayNumber === dayOfYear,
              today: dayNumber === dayOfYear,
              editable: dayNumber === dayOfYear || dayNumber === dayOfYear - 1,
              status,
              components: defaultComponents,
              message: dayDoc?.data()?.message || "",
            };
          });

        setEntries(calendarData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCalendarData();
    getUserGoals();
  }, [currentAuthUser]);

  // Real-time listener for Today & Yesterday
  useEffect(() => {
    if (!currentAuthUser) return;

    const updateEntries = (dayIndex: number, data: any) => {
      setEntries((prevEntries) => {
        const updatedEntries = [...prevEntries];
        const rawComponents = data.rawComponents || {};
        const completedComponents = data.completedComponents || {};

        // Determine the status
        let status: "completed" | "partial" | "incomplete" | "locked" =
          "locked";
        const completedCount = Object.values(
          completedComponents as CompletedComponents
        ).filter((v: number) => v === 1).length;
        if (dayIndex + 1 <= prevEntries.length) {
          if (completedCount === 5) {
            status = "completed";
          } else if (completedCount > 0) {
            status = "partial";
          } else {
            status = "incomplete";
          }
        }

        updatedEntries[dayIndex] = {
          ...updatedEntries[dayIndex],
          components: {
            meat: rawComponents.meat || 0,
            armor: rawComponents.armor || 0,
            resistanceMins: rawComponents.resistanceMins || 0,
            resistanceMeps: rawComponents.resistanceMeps || 0,
            cardioMins: rawComponents.cardioMins || 0,
            cardioMeps: rawComponents.cardioMeps || 0,
            hydration: rawComponents.hydration || 0,
          },
          status,
        };

        return updatedEntries;
      });
    };

    const todayListener = onSnapshot(
      query(
        collection(db, "activity"),
        where("userId", "==", currentAuthUser.uid),
        where("day", "==", dayOfYear)
      ),
      (snapshot) => {
        snapshot.docs.forEach((doc) => {
          updateEntries(dayOfYear - 1, doc.data());
        });
      }
    );

    // For the previous day
    const yesterday = dayOfYear - 1;
    const yesterdayListener =
      yesterday > 0 &&
      onSnapshot(
        query(
          collection(db, "activity"),
          where("userId", "==", currentAuthUser.uid),
          where("day", "==", yesterday)
        ),
        (snapshot) => {
          snapshot.docs.forEach((doc) => {
            updateEntries(yesterday - 1, doc.data());
          });
        }
      );

    return () => {
      todayListener();
      if (yesterdayListener) yesterdayListener();
    };
  }, [currentAuthUser]);

  // When user clicks a day in the mini-calendar
  const entryClicked = (index: number) => {
    setEntries((prev) =>
      prev.map((entry, i) => ({ ...entry, active: i === index }))
    );

    const dateForSelectedDay = dayjs(challengeStartDate).add(index, 'day').tz(EST);
    setSelectedDateString(dateForSelectedDay.format('MMMM D'));
  };

  // When user scrolls on input field
  const handleNumberInputWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement; // Cast to HTMLInputElement

    target.blur(); // Prevent value change
    e.stopPropagation();
    setTimeout(() => {
      target.focus(); // Refocus input
    }, 0);
  };

  // Sending raw data to the Cloud Function
  const handleLogData = async () => {
    setIsLoading(true);

    const activeEntry = entries.find((entry) => entry.active);
    if (!activeEntry) {
      alert("No active day selected.");
      setIsLoading(false);
      return;
    }

    if (!activeEntry.editable) {
      alert("You can only log data for today or yesterday.");
      setIsLoading(false);
      return;
    }

    try {
      // Prepare the callable refernce
      const logUserData = httpsCallable<
        {
          day: number;
          rawComponents: ComponentsData;
          message: string;
        },
        LogUserDataResponse
      >(functions, "logUserData");

      // Call the function with 'rawComponents'
      const response = await logUserData({
        day: parseInt(activeEntry.name),
        rawComponents: activeEntry.components,
        message: activeEntry.message,
      });

      console.log(response.data.message);
      alert("Data logged successfully!");
      setIsLoading(false);
    } catch (error) {
      console.error("Error logging data:", error);
      alert("Failed to log data.");
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="Log w-full h-full max-h-[90vh] 2xl:max-h-[93vh] bg-transparent col-span-1">
      <div className="w-full h-full flex flex-col justify-start items-center px-6">
        {/* Header */}
        {/* <div className='w-full flex flex-row justify-center items-center mb-6'> */}
        <h1 className="text-3xl text-dark dark:text-light font-inter font-black italic uppercase my-6">
          Log
        </h1>
        {/* </div> */}
        {/* Calendar */}
        <div
          className={`w-full ${
            !calendarVisible ? "h-11" : ""
          } flex flex-col justify-start items-center px-3 mb-6 bg-light border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull`}
        >
          {/* Toggle Calendar */}
          <button
            className="w-full"
            onClick={() => setCalendarVisible(!calendarVisible)}
          >
            <div className="w-full flex flex-row justify-between items-center">
              <h2 className="text-sm xl:text-lg text-dark font-inter font-medium tracking-wide">
                🗓️ {selectedDateString}
              </h2>
              <div className="w-6 xl:w-11 h-11 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 150 150"
                  className="w-[50%] xl:w-[35%] aspect-square"
                >
                  <path d="M0 0h150v150H0z" className="fill-none" />
                  <path
                    d="M72.691-.017c2.766-.03 5.054-1.055 7.234-3.283l46.797-48.218c1.674-1.687 2.523-3.67 2.523-6.113 0-4.925-3.931-8.924-8.798-8.924-2.391 0-4.695 1.007-6.486 2.818L70.129-18.284h5.188L31.384-63.737c-1.778-1.782-4.019-2.818-6.464-2.818-4.88 0-8.836 3.999-8.836 8.924 0 2.413.891 4.41 2.536 6.127L65.471-3.287c2.205 2.257 4.446 3.27 7.22 3.27Z"
                    transform="translate(-21.32 119.122) scale(1.32555)"
                  />
                </svg>
              </div>
            </div>
          </button>
          {calendarVisible && (
            <div className="w-full flex flex-col justify-center items-start mb-3">
              <div className="w-full flex flex-row justify-evenly flex-wrap mb-1">
                {entries.map((entry, index) => (
                  <button
                    key={index}
                    className={`w-[9%] 2xl:w-[6%] h-[5vh] 2xl:h-[4vh] m-[1%] flex flex-col justify-evenly items-center ${
                      entry.active ? "bg-c4 border-[1px] border-dark shadow-darkHalf" : entry.today ? "bg-lightGray" : ""
                    }`}
                    onClick={() => entryClicked(index)}
                  >
                    <div className="w-[50%] aspect-square flex justify-center items-center">
                      {entry.status === "completed" && (
                        <svg className="w-full fill-c3" viewBox="0 0 500 500">
                          <path d="M0 0h500v500H0z" className="fill-none" />
                          <path
                            d="M16.084-83.683c0 24.855 12.606 41.447 37.376 49.489 4.235 5.622 9.197 10.208 14.181 13.323V3.656H57.464c-8.925 0-14.108 5.473-14.108 14.107v11.255c0 3.738 2.944 6.476 6.595 6.476h60.488c3.651 0 6.594-2.738 6.594-6.476V17.763c0-8.634-5.182-14.107-14.107-14.107H92.749v-24.527c4.983-3.115 9.945-7.701 14.219-13.323 24.732-8.042 37.338-24.634 37.338-49.489 0-7.441-4.958-12.132-12.783-12.132h-11.399c-1.563-6.506-6.638-10.394-14.531-10.394H54.81c-7.867 0-12.981 3.835-14.544 10.394H28.867c-7.825 0-12.783 4.691-12.783 12.132Zm11.869 2.122c0-1.087.761-1.861 1.861-1.861h9.707v11.113c0 7.632 1.775 14.832 4.62 21.188-10.586-6.268-16.188-16.553-16.188-30.44Zm88.296 30.44c2.845-6.356 4.619-13.556 4.619-21.188v-11.113h9.708c1.1 0 1.861.774 1.861 1.861 0 13.887-5.602 24.143-16.188 30.44Z"
                            transform="translate(-32.969 374.759) scale(3.52851)"
                            className="stroke-[10px] stroke-dark"
                          />
                        </svg>
                      )}
                      {entry.status === "partial" && (
                        <svg className="w-full fill-c2" viewBox="0 0 500 500">
                          <path d="M0 0h500v500H0z" className="fill-none" />
                          <path
                            d="M40.379 26.91c3.313 2.51 7.337 1.741 11.867-1.584l32.32-23.713 32.329 23.713c4.534 3.325 8.558 4.094 11.872 1.584 3.233-2.443 3.929-6.521 2.114-11.726l-12.727-37.969 32.586-23.402c4.53-3.191 6.463-6.858 5.126-10.772-1.299-3.852-4.965-5.692-10.51-5.692h-39.961l-12.176-37.931c-1.71-5.317-4.549-8.193-8.653-8.193-4.053 0-6.905 2.876-8.64 8.193L63.75-62.651H23.76c-5.516 0-9.157 1.84-10.481 5.662-1.328 3.944.621 7.611 5.126 10.802l32.586 23.402-12.727 37.969c-1.814 5.205-1.119 9.283 2.115 11.726Z"
                            transform="translate(-44.868 390.122) scale(3.48644)"
                            className="stroke-[10px] stroke-dark"
                          />
                        </svg>
                      )}
                      {entry.status === "incomplete" && (
                        <div className="w-full aspect-square rounded-full border-[1px] border-dark bg-c1" />
                      )}
                      {entry.status === "locked" && (
                        <svg
                          className="w-full fill-lightGray"
                          viewBox="0 0 500 500"
                        >
                          <path d="M0 0h500v500H0z" className="fill-none" />
                          <path
                            d="M32.553 26.833h60.921c9.764 0 14.874-5.217 14.874-15.794V-35.39c0-10.494-5.11-15.71-14.874-15.71H32.553c-9.751 0-14.861 5.216-14.861 15.71v46.429c0 10.577 5.11 15.794 14.861 15.794Zm-2.652-72.401h13.39v-20.674c0-13.553 8.725-21.48 19.71-21.48 10.968 0 19.803 7.927 19.803 21.48v20.674h13.39v-19.954c0-22.365-14.99-35.094-33.193-35.094-18.177 0-33.1 12.729-33.1 35.094v19.954Z"
                            transform="translate(2.763 394.73) scale(3.92314)"
                            className="stroke-[10px] stroke-dark"
                          />
                        </svg>
                      )}
                    </div>
                    <h3 className='text-xs text-dark font-inter font-bold'>{entry.name}</h3>
                  </button>
                ))}
              </div>
              <div className="flex flex-row">
                <svg className="w-3 aspect-square fill-c3 mr-1" viewBox="0 0 500 500">
                  <path d="M0 0h500v500H0z" className="fill-none" />
                  <path
                    d="M16.084-83.683c0 24.855 12.606 41.447 37.376 49.489 4.235 5.622 9.197 10.208 14.181 13.323V3.656H57.464c-8.925 0-14.108 5.473-14.108 14.107v11.255c0 3.738 2.944 6.476 6.595 6.476h60.488c3.651 0 6.594-2.738 6.594-6.476V17.763c0-8.634-5.182-14.107-14.107-14.107H92.749v-24.527c4.983-3.115 9.945-7.701 14.219-13.323 24.732-8.042 37.338-24.634 37.338-49.489 0-7.441-4.958-12.132-12.783-12.132h-11.399c-1.563-6.506-6.638-10.394-14.531-10.394H54.81c-7.867 0-12.981 3.835-14.544 10.394H28.867c-7.825 0-12.783 4.691-12.783 12.132Zm11.869 2.122c0-1.087.761-1.861 1.861-1.861h9.707v11.113c0 7.632 1.775 14.832 4.62 21.188-10.586-6.268-16.188-16.553-16.188-30.44Zm88.296 30.44c2.845-6.356 4.619-13.556 4.619-21.188v-11.113h9.708c1.1 0 1.861.774 1.861 1.861 0 13.887-5.602 24.143-16.188 30.44Z"
                    transform="translate(-32.969 374.759) scale(3.52851)"
                    className="stroke-[10px] stroke-dark"
                  />
                </svg>
                <h4 className='text-xs text-dark font-inter font-bold'> = Completed</h4>
              </div>
              <div className="flex flex-row">
                <svg className="w-3 aspect-square fill-c2 mr-1" viewBox="0 0 500 500">
                  <path d="M0 0h500v500H0z" className="fill-none" />
                  <path
                    d="M40.379 26.91c3.313 2.51 7.337 1.741 11.867-1.584l32.32-23.713 32.329 23.713c4.534 3.325 8.558 4.094 11.872 1.584 3.233-2.443 3.929-6.521 2.114-11.726l-12.727-37.969 32.586-23.402c4.53-3.191 6.463-6.858 5.126-10.772-1.299-3.852-4.965-5.692-10.51-5.692h-39.961l-12.176-37.931c-1.71-5.317-4.549-8.193-8.653-8.193-4.053 0-6.905 2.876-8.64 8.193L63.75-62.651H23.76c-5.516 0-9.157 1.84-10.481 5.662-1.328 3.944.621 7.611 5.126 10.802l32.586 23.402-12.727 37.969c-1.814 5.205-1.119 9.283 2.115 11.726Z"
                    transform="translate(-44.868 390.122) scale(3.48644)"
                    className="stroke-[10px] stroke-dark"
                  />
                </svg>
                <h4 className='text-xs text-dark font-inter font-bold'> = In Progress</h4>
              </div>
              <div className="flex flex-row">
                <div className="w-3 h-3 rounded-full border-[1px] border-dark bg-c1 mr-1" />
                <h4 className='text-xs text-dark font-inter font-bold'> = Incomplete</h4>
              </div>
              <div className="flex flex-row">
                <svg
                  className="w-3 aspect-square fill-lightGray mr-1"
                  viewBox="0 0 500 500"
                >
                  <path d="M0 0h500v500H0z" className="fill-none" />
                  <path
                    d="M32.553 26.833h60.921c9.764 0 14.874-5.217 14.874-15.794V-35.39c0-10.494-5.11-15.71-14.874-15.71H32.553c-9.751 0-14.861 5.216-14.861 15.71v46.429c0 10.577 5.11 15.794 14.861 15.794Zm-2.652-72.401h13.39v-20.674c0-13.553 8.725-21.48 19.71-21.48 10.968 0 19.803 7.927 19.803 21.48v20.674h13.39v-19.954c0-22.365-14.99-35.094-33.193-35.094-18.177 0-33.1 12.729-33.1 35.094v19.954Z"
                    transform="translate(2.763 394.73) scale(3.92314)"
                    className="stroke-[10px] stroke-dark"
                  />
                </svg>
                <h4 className='text-xs text-dark font-inter font-bold'> = Locked</h4>
              </div>
            </div>
          )}
        </div>
        {/* The 5 Components Input */}
        <div className="w-full h-full mb-6 overflow-y-scroll">
          {/* MEAT */}
          {(() => {
            const activeEntry = entries.find((e) => e.active);
            const val = activeEntry?.components.meat ?? 0;
            return (
              <div key="meat" className="w-full h-24 py-2 px-3 mb-6 flex flex-col justify-start items-start bg-c1 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                <h3 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic">MEAT</h3>
                <div className="flex flex-row justify-between items-end">
                  <input
                    type="number"
                    value={val}
                    onChange={(e) => {
                      const newVal = parseInt(e.target.value) || 0;
                      setEntries((prev) =>
                        prev.map((entry) =>
                          entry.active
                            ? {
                              ...entry,
                              components: {
                                ...entry.components,
                                meat: newVal,
                              },
                            }
                          : entry
                        )
                      );
                    }}
                    disabled={!activeEntry?.editable}
                    onWheel={handleNumberInputWheel}
                    className="w-full text-xl font-inter font-bold text-center bg-transparent p-2 focus:outline-none border-b-2 border-dark"
                  />
                  <h4 className="text-base font-inter text-nowrap"> / {goals.proteinGoal} grams</h4>
                </div>
              </div>
            );
          })()}

          {/* ARMOR */}
          {(() => {
            const activeEntry = entries.find((e) => e.active);
            const val = activeEntry?.components.armor ?? 0;
            return (
              <div key="armor" className="w-full h-24 py-2 px-3 mb-6 flex flex-col justify-start items-start bg-c2 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                <h3 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic">ARMOR</h3>
                <div className="flex flex-row justify-between items-end">
                  <input
                    type="number"
                    value={val}
                    onChange={(e) => {
                      const newVal = parseInt(e.target.value) || 0;
                      setEntries((prev) =>
                        prev.map((entry) =>
                          entry.active
                            ? {
                              ...entry,
                              components: {
                                ...entry.components,
                                armor: newVal,
                              },
                            }
                          : entry
                        )
                      );
                    }}
                    disabled={!activeEntry?.editable}
                    onWheel={handleNumberInputWheel}
                    className="w-full text-xl font-inter font-bold text-center bg-transparent p-2 focus:outline-none border-b-2 border-dark"
                  />
                  <h4 className="text-base font-inter text-nowrap"> / 20 mins</h4>
                </div>
              </div>
            );
          })()}

          {/* RESISTANCE (Mins + MEPs in one box) */}
          {(() => {
            const activeEntry = entries.find((e) => e.active);
            const minsVal = activeEntry?.components.resistanceMins ?? 0;
            const mepsVal = activeEntry?.components.resistanceMeps ?? 0;
            return (
              <div key="resistance" className="w-full min-h-24 py-2 px-3 mb-6 flex flex-col justify-start items-start bg-c3 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                <h3 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic">RESISTANCE</h3>
                <div className="flex flex-col md:flex-row justify-start items-center">
                  <div className="flex flex-row justify-between items-end">
                    <input
                      type="number"
                      value={minsVal}
                      onChange={(e) => {
                        const newVal = parseInt(e.target.value) || 0;
                        setEntries((prev) =>
                          prev.map((entry) =>
                            entry.active
                              ? {
                                ...entry,
                                components: {
                                  ...entry.components,
                                  resistanceMins: newVal,
                                },
                              }
                            : entry
                          )
                        );
                      }}
                      disabled={!activeEntry?.editable}
                      onWheel={handleNumberInputWheel}
                      className="w-full text-xl font-inter font-bold text-center bg-transparent p-2 focus:outline-none border-b-2 border-dark"
                    />
                    <h4 className="text-base font-inter text-nowrap"> / 30 mins</h4>
                  </div>
                  <div className="flex flex-row justify-between items-end ml-3">
                    <input
                      type="number"
                      value={mepsVal}
                      onChange={(e) => {
                        const newVal = parseInt(e.target.value) || 0;
                        setEntries((prev) =>
                          prev.map((entry) =>
                            entry.active
                              ? {
                                ...entry,
                                components: {
                                  ...entry.components,
                                  resistanceMeps: newVal,
                                },
                              }
                            : entry
                          )
                        );
                      }}
                      disabled={!activeEntry?.editable}
                      onWheel={handleNumberInputWheel}
                      className="w-full text-xl font-inter font-bold text-center bg-transparent p-2 focus:outline-none border-b-2 border-dark"
                    />
                    <h4 className="text-base font-inter text-nowrap"> / 100 MEPs</h4>
                  </div>
                </div>
              </div>
            )
          })()}

          {/* CARDIO (Mins + MEPs in one box) */}
          {(() => {
            const activeEntry = entries.find((e) => e.active);
            const minsVal = activeEntry?.components.cardioMins ?? 0;
            const mepsVal = activeEntry?.components.cardioMeps ?? 0;
            return (
              <div key="cardio" className="w-full min-h-24 py-2 px-3 mb-6 flex flex-col justify-start items-start bg-c4 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                <h3 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic">CARDIO</h3>
                <div className="flex flex-col md:flex-row justify-start items-center">
                  <div className="flex flex-row justify-between items-end">
                    <input
                      type="number"
                      value={minsVal}
                      onChange={(e) => {
                        const newVal = parseInt(e.target.value) || 0;
                        setEntries((prev) =>
                          prev.map((entry) =>
                            entry.active
                              ? {
                                ...entry,
                                components: {
                                  ...entry.components,
                                  cardioMins: newVal,
                                },
                              }
                            : entry
                          )
                        );
                      }}
                      disabled={!activeEntry?.editable}
                      onWheel={handleNumberInputWheel}
                      className="w-full text-xl font-inter font-bold text-center bg-transparent p-2 focus:outline-none border-b-2 border-dark"
                    />
                    <h4 className="text-base font-inter text-nowrap"> / 30 mins</h4>
                  </div>
                  <div className="flex flex-row justify-between items-end ml-3">
                    <input
                      type="number"
                      value={mepsVal}
                      onChange={(e) => {
                        const newVal = parseInt(e.target.value) || 0;
                        setEntries((prev) =>
                          prev.map((entry) =>
                            entry.active
                              ? {
                                ...entry,
                                components: {
                                  ...entry.components,
                                  cardioMeps: newVal,
                                },
                              }
                            : entry
                          )
                        );
                      }}
                      disabled={!activeEntry?.editable}
                      onWheel={handleNumberInputWheel}
                      className="w-full text-xl font-inter font-bold text-center bg-transparent p-2 focus:outline-none border-b-2 border-dark"
                    />
                    <h4 className="text-base font-inter text-nowrap"> / 100 MEPs</h4>
                  </div>
                </div>
              </div>
            )
          })()}

          {/* HYDRATION */}
          {(() => {
            const activeEntry = entries.find((e) => e.active);
            const val = activeEntry?.components.hydration ?? 0;
            return (
              <div key="hydration" className="w-full h-24 py-2 px-3 mb-6 flex flex-col justify-start items-start bg-c5 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                <h3 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic">HYDRATION</h3>
                <div className="flex flex-row justify-between items-end">
                  <input
                    type="number"
                    value={val}
                    onChange={(e) => {
                      const newVal = parseInt(e.target.value) || 0;
                      setEntries((prev) =>
                        prev.map((entry) =>
                          entry.active
                            ? {
                              ...entry,
                              components: {
                                ...entry.components,
                                hydration: newVal,
                              },
                            }
                          : entry
                        )
                      );
                    }}
                    disabled={!activeEntry?.editable}
                    onWheel={handleNumberInputWheel}
                    className="w-full text-xl font-inter font-bold text-center bg-transparent p-2 focus:outline-none border-b-2 border-dark"
                  />
                  <h4 className="text-base font-inter text-nowrap"> / {goals.hydrationGoal} oz</h4>
                </div>
              </div>
            )
          })()}

          {/* MESSAGE BOX */}
          <div key="message" className="w-full min-h-24 py-2 px-3 mb-6 flex flex-col justify-start items-start bg-lightGray border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
            <h3 className="ttext-lg 2xl:text-xl text-dark font-inter font-extrabold italic">MESSAGE</h3>
            <input
              type="text"
              value={entries.find((entry) => entry.active)?.message ?? ""}
              onChange={(e) => {
                const val = e.target.value || "";
                setEntries((prev) =>
                  prev.map((entry) => {
                    if (entry.active) {
                      return {
                        ...entry,
                        message: val,
                      };
                    }
                    return entry;
                  })
                );
              }}
              disabled={!entries.find((entry) => entry.active)?.editable}
              className="w-full text-xl bg-transparent p-2 focus:outline-none border-b-2 border-dark"
            />
          </div>
        </div>

        {/* LOG DATA BUTTON */}
        <button
          className={`w-full h-14 flex justify-center items-center bg-orange border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull mb-28 md:mb-6 ${
            entries.find((entry) => entry.active)?.editable ? "" : "opacity-50"
          }`}
          onClick={handleLogData}
          disabled={!entries.find((entry) => entry.active)?.editable}
        >
          <h2 className="text-2xl text-light font-inter font-black tracking-widest uppercase">
            Log Data
          </h2>
        </button>
      </div>
      {/* {isLoading ? <Loader /> : null} */}
    </div>
  );
}

export default Log;
