import { useEffect, useState } from "react";

function ReactiveBGTexture() {
  // Check window size to determine if user is on mobile device
  const [isMobile, setIsMobile] = useState(false);
  const [isVertical, setIsVertical] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1320); // Previous 1024
      setIsVertical(window.innerWidth > window.innerHeight);
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isVertical ? (
        <svg
          className="w-screen fixed top-0 left-0 -translate-x-52 overflow-hidden opacity-25 dark:opacity-50"
          viewBox={isMobile ? "0 0 960 540" : "0 0 1920 1080"}
        >
          <path d="M0 0h1920v1080H0z" className="fill-none" />
          <clipPath id="a">
            <path d="M0 0h1920v1080H0z" />
          </clipPath>
          <g clip-path="url(#a)">
            <path
              d="M-6.213 983.344h226.405v41.123H-6.213z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 213.979 -927.811)"
            />
            <path
              d="M-6.213 601.815h226.405v41.123H-6.213z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 213.979 -164.753)"
            />
            <path
              d="M179.324 601.815h40.868v422.389h-40.868z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 399.516 -546.019)"
            />
            <path
              d="M179.325 792.448h907.43v41.123h-907.43z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 1266.08 -546.019)"
            />
            <path
              d="M-6.213 242.846h226.405v41.123H-6.213z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 213.979 553.185)"
            />
            <path
              d="M179.324-5.692h40.868v289.398h-40.868z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 399.516 801.986)"
            />
            <path
              d="M1050.14 58.65h40.868v774.921h-40.868z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 2141.16 187.779)"
            />
            <path
              d="M185.921 58.65h900.834v41.123H185.921z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 1272.68 921.577)"
            />
            <path
              d="M1070.58 404.559h796.067v41.123H1070.58z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 2937.22 229.759)"
            />
            <path
              d="M1825.78 411.182h40.868v674.51h-40.868z"
              className="fill-lightGray dark:fill-darkGray"
              transform="matrix(-1 0 0 1 3692.42 -416.874)"
            />
          </g>
        </svg>
      ) : (
        <svg
          className="w-screen fixed top-0 left-0 overflow-hidden opacity-25 dark:opacity-50"
          viewBox="0 0 1080 1920"
        >
          <path d="M0 0h1080v1920H0z" className="fill-none" />
          <path
            d="M466.743 512.787h812.514v36.358H466.743z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 1080 1110.354)"
          />
          <path
            d="M466.743 266.516h36.593v528.899h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 600.05 1110.354)"
          />
          <path
            d="M0 927.832h211.724v36.358H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 596.894)"
          />
          <path
            d="M0 590.515h211.724v36.358H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 1014.193)"
          />
          <path
            d="M175.131 590.515h36.593v373.442h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 239.292 805.69)"
          />
          <path
            d="M0 435.058h211.724v36.358H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 1206.514)"
          />
          <path
            d="M0 97.741h211.724v36.358H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 1623.812)"
          />
          <path
            d="M175.131 97.741h36.593v373.442h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 239.292 1415.308)"
          />
          <path
            d="M185.76 759.057h317.576v36.358H185.76z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 426.245 805.69)"
          />
          <path
            d="M185.76 266.516h317.576v36.358H185.76z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 426.245 1415.018)"
          />
          <path
            d="M1242.66 266.516h36.593v528.899h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 1110.354)"
          />
          <path
            d="M1534.28 97.741h211.724v36.358H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 1623.812)"
          />
          <path
            d="M1534.28 435.058h211.724v36.358H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 1206.514)"
          />
          <path
            d="M1534.28 97.974h36.593v373.442h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 1415.018)"
          />
          <path
            d="M1534.28 590.514h211.723v36.358H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 1014.193)"
          />
          <path
            d="M1534.28 927.832h211.723v36.358H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 596.894)"
          />
          <path
            d="M1534.28 590.748h36.593V964.19h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 805.399)"
          />
          <path
            d="M1242.66 266.516h317.576v36.358H1242.66z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 1415.018)"
          />
          <path
            d="M1242.66 759.057h317.576v36.358H1242.66z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 805.69)"
          />
          <path
            d="M466.743 2060.86h812.514v36.358H466.743z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 1080 -804.786)"
          />
          <path
            d="M466.743 1814.59h36.593v528.899h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 600.05 -804.786)"
          />
          <path
            d="M0 2475.9h211.724v36.358H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 -1318.244)"
          />
          <path
            d="M0 2138.58h211.724v36.357H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 -900.94)"
          />
          <path
            d="M175.131 2138.58h36.593v373.442h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 239.292 -1109.45)"
          />
          <path
            d="M0 1983.13h211.724v36.358H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 -708.625)"
          />
          <path
            d="M0 1645.81h211.724v36.358H0z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 130.963 -291.326)"
          />
          <path
            d="M175.131 1645.81h36.593v373.442h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 239.292 -499.83)"
          />
          <path
            d="M185.76 2307.13h317.576v36.357H185.76z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 426.245 -1109.45)"
          />
          <path
            d="M185.76 1814.59h317.576v36.358H185.76z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(-.61856 0 0 .61856 426.245 -500.121)"
          />
          <path
            d="M1242.66 1814.59h36.593v528.899h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -804.786)"
          />
          <path
            d="M1534.28 1645.81h211.724v36.358H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -291.326)"
          />
          <path
            d="M1534.28 1983.13h211.724v36.358H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -708.625)"
          />
          <path
            d="M1534.28 1646.04h36.593v373.442h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -500.121)"
          />
          <path
            d="M1534.28 2138.59h211.724v36.357H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -900.946)"
          />
          <path
            d="M1534.28 2475.9h211.724v36.358H1534.28z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -1318.244)"
          />
          <path
            d="M1534.28 2138.82h36.593v373.442h-36.593z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -1109.74)"
          />
          <path
            d="M1242.66 1814.59h317.576v36.358H1242.66z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -500.121)"
          />
          <path
            d="M1242.66 2307.13h317.576v36.357H1242.66z"
            className="fill-lightGray dark:fill-darkGray"
            transform="matrix(.61856 0 0 .61856 0 -1109.45)"
          />
        </svg>
      )}
    </>
  );
}

export default ReactiveBGTexture;
