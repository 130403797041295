import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { browserSessionPersistence, getAuth, GoogleAuthProvider, setPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCvLGlaLOhTPnbyJYczU4k7xZpwu4r30MU",
  // authDomain: "efit-ba871.firebaseapp.com", // FOR DEVELOPMENT
  authDomain: "marchmadness.elevation.fit", // LIVE
  projectId: "efit-ba871",
  storageBucket: "efit-ba871.appspot.com",
  messagingSenderId: "916960279282",
  appId: "1:916960279282:web:2fc9a1eb4e9f0c51492e6b",
  measurementId: "G-RECYTDYQRB"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app, 'challenge-2025');
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

setPersistence(auth, browserSessionPersistence);

// if (window.location.hostname === 'localhost') {
//     connectFunctionsEmulator(functions, 'localhost', 5001);
// } // FOR DEVELOPMENT ONLY