import { useEffect, useState } from 'react';
import { auth, functions, db } from './FirebaseConfig';
import { User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { doc, setDoc } from 'firebase/firestore';
import Loader from './Loader';
import ReactiveBGTexture from './assets/ReactiveBGTexture';

import challengeLogo from './assets/Logo.svg';
import Log from './pages/Log';
import Activity from './pages/Activity';
import Leaderboard from './pages/Leaderboard';
import Stats from './pages/Stats';

// Global declarations
declare global {
  interface CheckRegistrationResponse {
    registered: boolean;
    data?: Record<string, any>; // Adjust based on the actual data structure
  }
}

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentAuthUser, setCurrentAuthUser] = useState<User | null>(null);
  const [firstName, setFirstName] = useState('');
  const [secondRegistrationVisible, setSecondRegistrationVisible] = useState(false);
  const [draftInviteVisible, setDraftInviteVisible] = useState(false);

  const [menuActive, setMenuActive] = useState(false);
  const [screenActive, setScreenActive] = useState('activity');

  const [weightGoal, setWeightGoal] = useState<number>();
  const [proteinGoal, setProteinGoal] = useState<number>();
  const [gender, setGender] = useState<string>('');
  const [hydrationGoal, setHydrationGoal] = useState<number>();

  // Check window size to determine if user is on mobile device
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1320); // Previous 1024
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check registration status through Firebase Function
  const handleRegistrationCheck = async (user: any) => {
    try {
      const registrationCheckFn = httpsCallable<
        void,
        CheckRegistrationResponse
      >(functions, 'registrationCheck');
      const result = await registrationCheckFn();
      return result;
    } catch (error) {
      console.error('Error checking registration:', error);
      return null;
    }
  };

  const initializeAuthFlow = async (user: any) => {
    setIsLoading(true);

    const registered = await handleRegistrationCheck(user);
    if (!registered || !registered.data.registered) {
      window.location.replace('/auth');
    } else if (!registered.data.data?.proteinGoal || !registered.data.data?.hydrationGoal) {
      setSecondRegistrationVisible(true);
      setCurrentAuthUser(user);
    } else {
      setSecondRegistrationVisible(false);
      setCurrentAuthUser(user);
      setFirstName(registered.data.data?.firstName);

      // 125 DRAFT INVITATION CODE HERE!
      if (registered.data.data?.points?.totalPoints >= 125 && (registered.data.data?.acceptedDraft === null || registered.data.data?.acceptedDraft === undefined)) {
        setDraftInviteVisible(true);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log('User signed in:', user);
          await initializeAuthFlow(user);
      } else {
        console.log('No user signed in.');
        setCurrentAuthUser(null);
        setIsLoading(false);
        window.location.replace('/auth');
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to handle Registration Submission
  const handleLogGoals = async () => {
    setIsLoading(true);

    if (!currentAuthUser) {
      alert('Authentication or Salesforce data is missing.');
      return;
    }

    if (!proteinGoal || proteinGoal === 0 || !gender || gender === '' || !hydrationGoal || hydrationGoal === 0) {
      alert('Please fill in all fields.');
      return;
    }

    if (gender === 'male' && hydrationGoal < 124) {
      alert('Your hydration goal must be greater than or equal to 124oz.');
      return
    } else if (gender === 'female' && hydrationGoal < 92) {
      alert('Your hydration goal must be greater than or equal to 92oz.');
      return
    }

    try {
      // Prepare data to send to registerUser function
      const setUserGoalsFn = httpsCallable(functions, 'setUserGoals');
      await setUserGoalsFn({
        weightGoal: weightGoal || null,
        proteinGoal,
        gender,
        hydrationGoal,
      });

      setSecondRegistrationVisible(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error setting user goals:', error);
      alert('Failed to set user goals.');
      setIsLoading(false);
    }
  };

  const acceptDraft = async () => {
    setIsLoading(true);
    if (currentAuthUser === null) {
      window.location.replace('/auth');
      return
    }

    await setDoc(doc(db, `users/${currentAuthUser.uid}`), { acceptedDraft: true }, { merge: true });
    setDraftInviteVisible(false);
    setIsLoading(false);
  }

  const declineDraft = async () => {
    setIsLoading(true);
    if (currentAuthUser === null) {
      window.location.replace('/auth');
      return
    }

    await setDoc(doc(db, `users/${currentAuthUser.uid}`), { acceptedDraft: false }, { merge: true });
    setDraftInviteVisible(false);
    setIsLoading(false);
  }

  return (
    <div className='App w-screen h-screen fixed bg-light dark:bg-dark'>
      <ReactiveBGTexture />
      <div className='w-screen h-screen flex flex-col justify-start items-center bg-transparent fixed top-0 left-0'>
        <div className='w-full h-[10vh] 2xl:h-[7vh] flex flex-row justify-between items-center p-3 border-b-[1px] border-lightGray dark:border-darkGray'>
          {isMobile && !secondRegistrationVisible && (
            <button className='w-10 h-10 flex justify-center items-center' onClick={() => setMenuActive(!menuActive)}>
              <svg className='w-full fill-dark dark:fill-light' viewBox="0 0 500 500">
                <path d="M0 0h500v500H0z" className='fill-none'/>
                <clipPath id="a">
                  <path d="M0 0h500v500H0z"/>
                </clipPath>
                <g clip-path="url(#a)">
                  <path
                    d="M22.531 1.174H140.36c3.62 0 6.485-3.056 6.485-6.631 0-3.649-2.836-6.631-6.485-6.631H22.531c-3.582 0-6.447 3.04-6.447 6.631 0 3.517 2.894 6.631 6.447 6.631Zm0-29.722H140.36c3.62 0 6.485-3.069 6.485-6.631 0-3.649-2.836-6.631-6.485-6.631H22.531c-3.582 0-6.447 3.04-6.447 6.631 0 3.503 2.894 6.631 6.447 6.631Zm0-29.731H140.36c3.62 0 6.485-3.069 6.485-6.592 0-3.65-2.836-6.631-6.485-6.631H22.531c-3.582 0-6.447 3.04-6.447 6.631 0 3.465 2.894 6.592 6.447 6.592Z"
                    transform="translate(-61.502 384.46) scale(3.82377)"
                  />
                </g>
              </svg>
            </button>
          )}
          <img className='h-full' src={challengeLogo} alt="March Madness" />
          {!isMobile && !secondRegistrationVisible && (
            <div className='flex flex-row justify-center items-center'>
              <button
                className={`text-xl text-dark dark:text-light font-inter ${screenActive === 'activity' ? 'font-bold' : 'font-extralight'} uppercase hover:font-bold`}
                onClick={() => setScreenActive('activity')}
              >
                Activity
              </button>
              <h1 className='text-xl text-dark dark:text-light font-inter font-extralight uppercase m-3'>|</h1>
              <button
                className={`text-xl text-dark dark:text-light font-inter ${screenActive === 'leaderboard' ? 'font-bold' : 'font-extralight'} uppercase hover:font-bold`}
                onClick={() => setScreenActive('leaderboard')}
              >
                Leaderboard
              </button>
            </div>
          )}
          {isMobile && !secondRegistrationVisible && (
            <div className='w-10 h-10 bg-transparent' />
          )}
          {/* <p>{window.innerWidth}</p> */}
        </div>
        {secondRegistrationVisible || draftInviteVisible ?
          <>
            {secondRegistrationVisible &&
              <div className='w-full h-full flex justify-center items-center'>
                <div className='w-[95%] max-w-4xl flex flex-col md:flex-row justify-center items-center mb-32 md:mb-0'>
                  <div className='w-full h-full py-4 px-4 bg-c1 border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble'>
                    <h1 className='text-2xl text-dark font-inter font-bold italic uppercase mb-4'>
                      Set Your Goals
                    </h1>
                    <div className='w-full flex flex-col justify-center items-end gap-5'>
                      <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-5'>
                        <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                          <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                            Current Weight/Weight Goal
                          </h2>
                          <input
                            className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                            type='number'
                            placeholder='0lbs (Optional)'
                            value={weightGoal}
                            onChange={(e) => {
                              const newVal = parseInt(e.target.value) || 0;
                              setWeightGoal(newVal);
                              
                              setProteinGoal(Math.round((newVal * 0.7)*100)/100);
                            }}
                          ></input>
                        </div>
                        <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                          <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                            Protein Goal
                          </h2>
                          <input
                            className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                            type='number'
                            placeholder='0g'
                            value={proteinGoal}
                            onChange={(e) => {
                              const newVal = parseInt(e.target.value) || 0;
                              setProteinGoal(newVal);
                            }}
                          ></input>
                        </div>
                        <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                          <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                            Gender
                          </h2>
                          <div className='w-full flex flex-row justify-center items-center gap-2'>
                            <button
                              className={`
                                w-full h-7 text-xl font-inter font-normal border-[1px]
                                ${gender === 'male' ? 'text-dark bg-blue border-dark shadow-darkFull' : 'text-lightGray bg-lightBlue border-lightGray shadow-lightGrayFull'}
                              `}
                              onClick={() => {
                                setGender('male');

                                setHydrationGoal(124);
                              }}
                            >
                              Male
                            </button>
                            <button
                              className={`
                                w-full h-7 text-xl font-inter font-normal border-[1px]
                                ${gender === 'female' ? 'text-dark bg-red border-dark shadow-darkFull' : 'text-lightGray bg-lightRed border-lightGray shadow-lightGrayFull'}
                              `}
                              onClick={() => {
                                setGender('female');

                                setHydrationGoal(92);
                              }}
                            >
                              Female
                            </button>
                          </div>
                        </div>
                        <div className='w-full h-20 flex flex-col justify-center items-start border-[1px] border-dark shadow-darkFull bg-white p-2'>
                          <h2 className='text-base text-dark font-inter font-medium uppercase mb-1'>
                            Hydration Goal
                          </h2>
                          <input
                            className='w-full text-xl text-dark font-inter font-normal focus:outline-none placeholder:text-lightGray'
                            type='number'
                            placeholder='0oz'
                            value={hydrationGoal}
                            onChange={(e) => console.log(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div className='w-full flex flex-row justify-between items-end'>
                        <a
                          className='text-base text-dark font-inter font-medium'
                          href='https://staff.elevationchurch.org/support/tickets/new?category=Software&sub_category=Account%20Access&subject=March%20Madness%20eFit%20Support'
                          target='_blank'
                        >
                          Need help? Click here for support.
                        </a>
                        <button
                          className='h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-white px-5'
                          onClick={handleLogGoals}
                        >
                          <h2 className='text-xl text-dark font-inter font-medium uppercase'>
                            Start Challenge
                          </h2>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {draftInviteVisible &&
              <div className='w-full h-full flex justify-center items-center'>
                <div className='w-[95%] max-w-4xl flex flex-col md:flex-row justify-center items-center mb-32 md:mb-0'>
                  <div className='w-full h-full py-4 px-4 bg-lightGray border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble'>
                    <h1 className='text-2xl text-dark font-inter font-bold italic uppercase mb-4'>
                      Congratulations, {firstName} 🥳
                    </h1>
                    <div className='w-full mb-4 text-left'>
                      <h2 className='text-lg text-dark font-inter font-normal mb-4'>You crushed Challenge 1, hitting the <span className='font-bold'>125-point</span> milestone! Now, it's time for the next level - <span className='font-bold'>March Madness Team Challenge</span>. This is your official <span className='font-bold'>Draft Invitation</span> to complete in the bracket-style team competition starting <span className='font-bold'>March 18th</span>.</h2>
                      <h2 className='text-lg text-dark font-inter font-bold mb-4'>What's Next?</h2>
                      <ul className='ml-6 md:ml-12 mb-4 text-lg text-dark font-inter font-normal list-disc'>
                          <li><span className='font-bold'>Team Draft on March 11th</span> - You'll be drafted onto a team to compete for ultimate glory.</li>
                          <li><span className='font-bold'>Team-based fitness battles</span> - Work together to advance in the bracket!</li>
                          <li><span className='font-bold'>Win prizes</span> - Compete for gift cards and the championship title!</li>
                      </ul>
                      <h2 className='text-lg text-dark font-inter font-normal mb-8'>Let's keep the momentum going - <span className='font-bold'>March Madness awaits! Will you accept the challenge? 💪</span></h2>
                    </div>
                    <div className='w-full flex flex-col justify-center items-end gap-5'>
                      <div className='w-full flex flex-row justify-between items-end'>
                        <button
                          className='w-60 h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-darkGray px-5 text-xl text-light font-inter font-medium uppercase text-center hover:font-bold'
                          onClick={() => setDraftInviteVisible(false)}
                        >
                          Ask Again Later
                        </button>
                        <button
                          className='w-60 h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-red px-5 text-xl text-dark font-inter font-medium uppercase text-center hover:font-bold'
                          onClick={() => declineDraft()}
                        >
                          Decline Invite
                        </button>
                        <button
                          className='w-60 h-20 border-[1px] border-dark shadow-darkFull flex justify-center items-center bg-green px-5 text-xl text-dark font-inter font-medium uppercase text-center hover:font-bold'
                          onClick={() => acceptDraft()}
                        >
                          Accept Invite
                        </button>
                      </div>
                      <h3 className='w-full text-center text-sm text-dark font-inter font-light italic'>If you have any questions, reach out to Buck Buhler for assistance.</h3>
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
        :
          <>
            {!isMobile ?
              // ON DESKTOP
              <div className='w-full h-full max-h-[90vh] 2xl:max-h-[93vh] grid grid-cols-4 2xl:grid-cols-5'>
                {screenActive === 'activity' && (
                  <div className='col-span-3 2xl:col-span-4 flex flex-col justify-start items-center border-lightGray dark:border-darkGray border-r-[1px]'>
                    {currentAuthUser ? <Stats currentAuthUser={currentAuthUser} isMobile={isMobile} /> : null}
                    {currentAuthUser ? <Activity currentAuthUser={currentAuthUser} isMobile={isMobile} /> : null}
                  </div>
                )}
                {screenActive === 'leaderboard' && (
                  <>
                    {currentAuthUser ? <Leaderboard currentAuthUser={currentAuthUser} /> : null}
                  </>
                )}
                {currentAuthUser ? <Log currentAuthUser={currentAuthUser} /> : null}
              </div>
            :
              <>
                {!menuActive ?
                  <div className='w-full h-full'>
                    {screenActive === 'log' && (
                      <>
                        {currentAuthUser ? <Log currentAuthUser={currentAuthUser} /> : null}
                      </>
                    )}
                    {screenActive === 'activity' && (
                      <>
                        {currentAuthUser ? <Activity currentAuthUser={currentAuthUser} isMobile={isMobile} /> : null}
                      </>
                    )}
                    {screenActive === 'leaderboard' && (
                      <>
                        {currentAuthUser ? <Leaderboard currentAuthUser={currentAuthUser} /> : null}
                      </>
                    )}
                    {screenActive === 'stats' && (
                      <>
                        {currentAuthUser ? <Stats currentAuthUser={currentAuthUser} isMobile={isMobile} /> : null}
                      </>
                    )}
                  </div>
                  :
                  <div className='w-full h-full max-h-[90vh] 2xl:max-h-[93vh] bg-transparent flex flex-col justify-start items-center p-7'>
                      <div className='w-full flex flex-col justify-center items-center gap-5'>
                      <button
                        className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c4'
                        onClick={() => {
                          setScreenActive('log');
                          setMenuActive(false);
                        }}
                      >
                        <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                          <svg className='w-[50%] fill-c4' viewBox="0 0 500 500">
                            <path d="M0 0h500v500H0z" className='fill-none'/>
                            <path
                              d="M51.597-83.395h38.592c3.877 0 6.219-2.425 6.219-6.531v-6.578c0-4.12-2.342-6.532-6.219-6.532h-4.984c-.462-7.468-6.636-13.454-14.293-13.454-7.644 0-13.832 5.986-14.331 13.454h-4.984c-3.864 0-6.181 2.412-6.181 6.532v6.578c0 4.106 2.317 6.531 6.181 6.531Zm19.315-13.444c-3.146 0-5.71-2.607-5.71-5.71 0-3.13 2.564-5.656 5.71-5.656a5.652 5.652 0 0 1 5.672 5.656c0 3.103-2.527 5.71-5.672 5.71ZM37.448 38.545h66.89c13.618 0 21.402-7.813 21.402-21.619V-35.98l-13.883 13.895v38.85c0 5.04-2.849 7.935-8.045 7.935h-65.8c-5.235 0-8.07-2.895-8.07-7.935v-95.176c0-4.863 2.607-7.897 7.5-7.897h1.461c-.522-1.322-.765-2.842-.765-4.46v-5.723c0-1.328.205-2.619.651-3.713h-1.721c-13.377 0-20.984 8.016-20.984 21.632v95.498c0 13.806 7.759 21.619 21.364 21.619Zm74.409-106.886 10.446-10.446c.943-.957 1.945-1.712 3.028-2.473l-.029-1.829c-1.256-10.688-8.502-17.115-20.546-17.115h-1.722c.447 1.094.651 2.385.651 3.713v5.723c0 1.618-.242 3.138-.752 4.46h1.449c4.854 0 7.475 3.034 7.475 7.897v10.07ZM40.327-52.23c0 2.673 2.208 4.881 4.882 4.881h45.613l8.85-8.821c-.834-.607-1.942-.955-3.028-.955H45.209c-2.674 0-4.882 2.208-4.882 4.895Zm0 22.298c0 2.754 2.141 4.881 4.882 4.881h23.302l9.696-9.696H45.209c-2.712 0-4.882 2.128-4.882 4.815Zm4.882 28.438h7.238c2.674 0 4.882-2.208 4.882-4.894 0-2.674-2.17-4.815-4.882-4.815h-7.238c-2.712 0-4.882 2.141-4.882 4.815 0 2.686 2.208 4.894 4.882 4.894Zm92.351-58.97 4.212-4.275c2.807-2.832 2.908-6.661.266-9.282l-1.809-1.781c-2.346-2.25-6.272-1.846-8.929.782l-4.192 4.111 10.452 10.445ZM68.707.574 82.122-5.09l50.598-50.534-10.447-10.385-50.598 50.521-5.801 13.162c-.741 1.812 1.176 3.583 2.833 2.9Z"
                              transform="translate(-8.063 375.69) scale(3.22508)"
                            />
                          </svg>
                        </div>
                        <h2 className='text-xl text-dark font-inter font-semibold uppercase'>
                          Logging
                        </h2>
                      </button>
                      <button
                        className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c3'
                        onClick={() => {
                          setScreenActive('activity');
                          setMenuActive(false);
                        }}
                      >
                        <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                          <svg className='w-[50%] fill-c3' viewBox="0 0 500 500">
                            <path d="M0 0h500v500H0z" className='fill-none'/>
                            <path
                              d="M56.805 35.994c2.685 0 4.794-1.215 7.982-4.071l23.457-20.864h41.242c18.778 0 29.981-11.344 29.981-29.943v-50.174c0-18.599-11.203-29.981-29.981-29.981H46.065c-18.778 0-29.981 11.355-29.981 29.981v50.174c0 18.626 11.787 29.943 29.105 29.943h5.369v17.997c0 4.243 2.383 6.938 6.247 6.938Z"
                              transform="translate(-56.087 359.925) scale(3.48716)"
                            />
                          </svg>
                        </div>
                        <h2 className='text-xl text-dark font-inter font-semibold uppercase'>
                          Activity
                        </h2>
                      </button>
                      <button
                        className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c2'
                        onClick={() => {
                          setScreenActive('leaderboard');
                          setMenuActive(false);
                        }}
                      >
                        <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                          <svg className='w-[50%] fill-c2' viewBox='0 0 500 500'>
                            <path d='M0 0h500v500H0z' className='fill-none' />
                            <path
                              d='M16.084-83.683c0 24.855 12.606 41.447 37.376 49.489 4.235 5.622 9.197 10.208 14.181 13.323V3.656H57.464c-8.925 0-14.108 5.473-14.108 14.107v11.255c0 3.738 2.944 6.476 6.595 6.476h60.488c3.651 0 6.594-2.738 6.594-6.476V17.763c0-8.634-5.182-14.107-14.107-14.107H92.749v-24.527c4.983-3.115 9.945-7.701 14.219-13.323 24.732-8.042 37.338-24.634 37.338-49.489 0-7.441-4.958-12.132-12.783-12.132h-11.399c-1.563-6.506-6.638-10.394-14.531-10.394H54.81c-7.867 0-12.981 3.835-14.544 10.394H28.867c-7.825 0-12.783 4.691-12.783 12.132Zm11.869 2.122c0-1.087.761-1.861 1.861-1.861h9.707v11.113c0 7.632 1.775 14.832 4.62 21.188-10.586-6.268-16.188-16.553-16.188-30.44Zm88.296 30.44c2.845-6.356 4.619-13.556 4.619-21.188v-11.113h9.708c1.1 0 1.861.774 1.861 1.861 0 13.887-5.602 24.143-16.188 30.44Z'
                              transform='translate(-32.969 374.759) scale(3.52851)'
                            />
                          </svg>
                        </div>
                        <h2 className='text-xl text-dark font-inter font-semibold uppercase'>
                          Leaderboard
                        </h2>
                      </button>
                      <button
                        className='w-full h-20 flex flex-row justify-start items-center border-[1px] border-dark shadow-darkFull px-3 bg-c1'
                        onClick={() => {
                          setScreenActive('stats');
                          setMenuActive(false);
                        }}
                      >
                        <div className='h-[75%] aspect-square rounded-full mr-3 flex justify-center items-center bg-white'>
                          <svg className='w-[50%] fill-c1' viewBox='0 0 500 500'>
                            <path d='M0 0h500v500H0z' className='fill-none' />
                            <path
                              d='M16.084-83.683c0 24.855 12.606 41.447 37.376 49.489 4.235 5.622 9.197 10.208 14.181 13.323V3.656H57.464c-8.925 0-14.108 5.473-14.108 14.107v11.255c0 3.738 2.944 6.476 6.595 6.476h60.488c3.651 0 6.594-2.738 6.594-6.476V17.763c0-8.634-5.182-14.107-14.107-14.107H92.749v-24.527c4.983-3.115 9.945-7.701 14.219-13.323 24.732-8.042 37.338-24.634 37.338-49.489 0-7.441-4.958-12.132-12.783-12.132h-11.399c-1.563-6.506-6.638-10.394-14.531-10.394H54.81c-7.867 0-12.981 3.835-14.544 10.394H28.867c-7.825 0-12.783 4.691-12.783 12.132Zm11.869 2.122c0-1.087.761-1.861 1.861-1.861h9.707v11.113c0 7.632 1.775 14.832 4.62 21.188-10.586-6.268-16.188-16.553-16.188-30.44Zm88.296 30.44c2.845-6.356 4.619-13.556 4.619-21.188v-11.113h9.708c1.1 0 1.861.774 1.861 1.861 0 13.887-5.602 24.143-16.188 30.44Z'
                              transform='translate(-32.969 374.759) scale(3.52851)'
                            />
                          </svg>
                        </div>
                        <h2 className='text-xl text-dark font-inter font-semibold uppercase'>
                          Stats
                        </h2>
                      </button>
                    </div>
                  </div>
                }
              </>
            }
          </>
        }
        {isLoading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default App;
