import challengeLogo from '../assets/Logo.svg';

function ChallengeDetails() {
    return(
        <div className='w-screen overflow-y-auto min-h-screen flex flex-col justify-start items-center text-center bg-light'>
            <div className='w-[95%] md:max-w-[75%] lg:max-w-[50%] my-12 flex flex-col justify-center items-center text-[#202020]'>
                <img className='w-3/4 md:w-1/2 lg:w-1/4 m-auto mb-8' src={challengeLogo} alt="March Madness" />

                <h1 className='w-full mb-4 text-md font-inter font-medium text-center'>Alright, alright, alright! 2025 is here, and we're kicking it off with an exciting new challenge for both staff and spouses! The March Madness Staff Fitness Challenge is designed to help you build strong, sustainable habits while pushing you to crush your fitness and wellness goals.</h1>
                <h1 className='w-full mb-8 text-md font-inter font-medium text-center'>This is something we've never done before—a two-part challenge (BOGO - let's go!) that blends individual accountability with the thrill of a team-based March Madness fitness competition. Whether you're looking to level up your lifestyle or unleash your competitive side as you team up and battle it out, there's something for everyone. Let's make this your healthiest year yet!</h1>
                <h1 className='w-full mb-8 text-md font-inter font-medium text-center'>Check out the details below and get ready to dive in...</h1>

                <div className='w-full mb-4 text-left'>
                    <h2 className='text-xl font-inter font-bold uppercase'>DATES</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li>Registration</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>December 23rd</li>
                        </ul>
                        <li>Individual Challenge 1</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Dates: Tuesday, January 14th - Tuesday, February 18th (36 days)</li>
                            <li>What to expect: fitness, nutrition, and spiritual growth goals.</li>
                            <li>Focus: on consistency, building habits, and laying the foundation for the team competition ahead!</li>
                        </ul>
                        <li>3-Week Rest Break</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Dates: February 18th - March 10th</li>
                            <li>Use this time to recharge, recover, and prepare for the exciting team challenges ahead. Great for recovery, reflection, adjusting your goals, and preparing for the next challenge!</li>
                        </ul>
                        <li>Live Team Draft</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Date: March 11th</li>
                            <li>What's next: Get ready to be drafted into your team! This is where the real fun begins, as we form teams and set the stage for the March Madness team challenges to kick off!</li>
                        </ul>
                        <li>Team Competition Challenge 2</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Dates: March 18th - April 1st</li>
                            <li>The final stretch: Put your skills, strength, and endurance to the test as you compete with your team in exciting weekly head to head fitness challenges. Stay motivated, engage with your colleagues/teammates, and aim to win some cash money in the form of gift cards :)</li>
                        </ul>
                    </ul>

                    <h2 className='text-xl font-inter font-bold uppercase'>CHALLENGE 1 OF 2: INDIVIDUAL COMPETITION</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li>Duration: 36 Days</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Dates: Tuesday, January 14th - Tuesday, February 18th</li>
                        </ul>
                        <li>GOAL:</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Earn 125 out of 180 possible points by completing your daily habits. (1 point for each habit completed per day.)</li>
                            <li>Bonus Opportunity: Earn 155 points to be entered into a prize/gift card raffle!</li>
                            <li>Daily Goal: WIN THE DAY!</li>
                        </ul>
                        <li>Each day, reflect on your progress and whether you completed your daily habits in nutrition, fitness, hydration, and spiritual growth.</li>
                        <li>Prizes and Incentives:</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Earn 125 points to complete the challenge and win a prize.</li>
                            <li>Earn 155 points and gain entry into a prize/gift card raffle rewarding you for your extra effort.</li>
                        </ul>
                    </ul>

                    <h2 className='text-xl font-inter font-bold'>5 HABITS OF MARCH MADNESS</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li><b>M – MEAT (Protein-Focused Meals)</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>GOAL: Consume at least 0.7 grams of protein per pound of body weight or per your goal body weight (if you're working toward a weight goal).</li>
                            <li>MOTIVATION: Protein is essential for muscle repair, growth, and overall recovery. It supports energy levels, helps manage hunger, and aids in maintaining lean muscle mass.</li>
                            <li>Need help? Reach out to <span className='underline cursor-pointer text-orange' onClick={() => window.open('mailto:bbuhler@elevationchurch.org', '_blank')}>Buck</span> for personalized advice on calculating your protein needs.</li>
                        </ul>
                        <li><b>A – ARMOR (Armor of God: Bible Study)</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>GOAL: Dedicate at least 20 minutes per day to Bible study or devotional time.</li>
                            <li>MOTIVATION: Just as we need physical strength, we also need spiritual strength to face the challenges of life. Taking time for study and reflection helps build resilience, peace, and purpose in your daily journey.</li>
                            <li>BIBLE STUDY: You can do your own individual study or if you need help getting started you can join the 8 week staff study of Romans with LB which starts January 9th. Click <span className='underline cursor-pointer text-orange' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSffWcnsB5fR8QuXOSg50AJc3rubMT0WoYdF2yDio-uTV8aSxg/viewform', '_blank')}>HERE</span> to sign up.</li>
                            <li>TIP: Use this time to reflect, pray, and align yourself with your spiritual goals.</li>
                        </ul>
                        <li><b>R – RESISTANCE (Strength Training)</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>GOAL: Complete 30-45 minutes of challenging weight training or earn 100 Myzone MEPS in a strength-focused workout.</li>
                            <li>MOTIVATION: Strength training builds muscle, enhances metabolism, and improves functional movement for daily activities. Progressive overload is key to building long-lasting strength.</li>
                            <li><i>*Important: Resistance and cardio workouts must be logged separately so the same workout cannot count for both categories.</i></li>
                        </ul>
                        <li><b>C – CARDIO (Endurance and Heart Health)</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>GOAL: Complete 30-45 minutes of challenging cardio (or earn 100 Myzone MEPS) focused on improving heart health and endurance.</li>
                            <li>MOTIVATION: Cardiovascular exercise boosts heart health, increases lung capacity, and improves overall energy levels. It's essential for stamina, recovery, and overall vitality.</li>
                            <li>TIP: Try a mix of activities—HIIT, running, cycling, or even rowing—to keep it fresh and exciting.</li>
                            <li><i>*Important: Resistance and cardio workouts must be logged separately so the same workout cannot count for both categories.</i></li>
                        </ul>
                        <li><b>H – HYDRATION (Daily Water Intake)</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>GOAL:</li>
                            <ul className='ml-6 md:ml-12 list-disc'>
                                <li>Men: Drink at least 124 oz (15.5 cups) of water daily.</li>
                                <li>Women: Drink at least 92 oz (11.5 cups) of water daily.</li>
                            </ul>
                            <li>MOTIVATION: Proper hydration supports optimal performance, recovery, and energy levels. Staying hydrated aids digestion, detoxification, and improves mental clarity.</li>
                            <li>TIP: Carry a water bottle with you to stay on track throughout the day.</li>
                        </ul>
                    </ul>

                    <h1 className='w-full mb-8 text-md font-inter font-medium text-center'>Upon completion of Challenge 1, you will receive an invite to Challenge 2 — the team-based competition that takes your fitness journey to the next level! Only those that complete challenge 1 can compete in the 2nd Challenge.</h1>

                    <h2 className='text-xl font-inter font-bold uppercase'>CHALLENGE 2 OF 2: TEAM COMPETITION</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li>Type: Competitive fitness challenge, head-to-head bracket style (March Madness format).</li>
                        <li>Duration: 3-4 weeks of team workout competitions (workout and prize details to follow)</li>
                        <li>Bracket Structure:</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>E.g., 16 teams to 8 teams to 4 teams, or 8 teams to 4 teams to 2 teams.</li>
                            <li>Teams advance based on highest team scores.</li>
                        </ul>
                        <li>Eligibility:</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Must complete Challenge 1 to qualify and accept invite for Challenge 2.</li>
                            <li>Entered into Team Draft on March 11th.</li>
                            <li>Teams close after the Draft.</li>
                        </ul>
                        <li>Team Options:</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Staff and spouses can be drafted together or separately.</li>
                        </ul>
                        <li>Incentive:</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>March Madness Team Challenge shirts provided to all team members.</li>
                        </ul>
                    </ul>

                    <h2 className='text-xl font-inter font-bold uppercase'>LOGGING</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li>Logging will take place on the challenge website: <span className='underline cursor-pointer text-orange' onClick={() => window.open('https://elevation.fit/', '_blank')}>https://elevation.fit/</span> or eFit app.</li>
                        <li>24 hour grace period to log your points.</li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Each component logged earns up to 1 point per day</li>
                            <li>E.g. Individuals have until 11:59PM on Wednesday to enter your points earned on Tuesday before their points expire and will be unable to log/get credit for them</li>
                            <li>Category point totals and remaining points needed will be displayed in the app</li>
                            <li>Push notification reminder options will be available</li>
                        </ul>
                    </ul>
                </div>
                <h2 className='mb-8 text-base font-inter font-bold'>Contact <span className='underline cursor-pointer text-orange' onClick={() => window.open('mailto:bbuhler@elevationchurch.org', '_blank')}>Buck Buhler</span> if you have any questions or need assistance.</h2>
            </div>
        </div>
    )
}

export default ChallengeDetails;