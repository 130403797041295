import { useEffect, useState } from 'react';
import { db, auth, functions } from '../FirebaseConfig';
import { collection, getDoc, doc, onSnapshot, query, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import Loader from '../Loader';
import ReactiveBGTexture from '../assets/ReactiveBGTexture';
import challengeLogo from '../assets/Logo.svg';

// Interface Declarations
interface DraftUserDoc {
  email: string;
  firstName: string;
  lastname: string;
  profilePic: string;
  spouse: boolean | null;
  staff: boolean | null;
  totalPoints: number | null;
  id: string;
}
interface DraftUserDataResponse {
  success: boolean;
  message?: string;
}

function Draft() {
  // Global states
  const [isLoading, setIsLoading] = useState(true);
  const [currentAuthUser, setCurrentAuthUser] = useState<any>(null);
  const [draftUsers, setDraftUsers] = useState<any[]>([]);
  const [activeState, setActiveState] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  // Check registration status through Firebase Function
  const handleRegistrationCheck = async (user: any) => {
    try {
      const registrationCheckFn = httpsCallable<
        void,
        CheckRegistrationResponse
      >(functions, 'registrationCheck');
      const result = await registrationCheckFn();
      return result;
    } catch (error) {
      console.error('Error checking registration:', error);
      return null;
    }
  };

  const initializeAuthFlow = async (user: any) => {
    setIsLoading(true);

    try {
      // Check registration first
      const registered = await handleRegistrationCheck(user);
      if (!registered || !registered.data.registered) {
        window.location.replace('/auth');
        return;
      }

      if (!registered.data.data?.admin) {
        // Check if user has a team document
        const teamDocRef = doc(db, `draft/global/teams/${user.uid}`);
        const teamDoc = await getDoc(teamDocRef);
        
        if (!teamDoc.exists()) {
          window.location.replace('/auth');
          return;
        }
      }

      setCurrentAuthUser(user);
    } catch (error) {
      console.error('Error in auth flow:', error);
      window.location.replace('/auth');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log('User signed in:', user);
        await initializeAuthFlow(user);
      } else {
        console.log('No user signed in.');
        setCurrentAuthUser(null);
        setIsLoading(false);
        window.location.replace('/auth');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const getDraftUsers = async () => {
      setIsLoading(true);
      try {
        if (!currentAuthUser) {
          console.error("User is not signed in.");
          // window.location.replace('/auth');
          return;
        }

        onSnapshot(query(collection(db, 'draft/global/users'), orderBy('firstName', 'asc')), (snap) => {
          if (!snap.empty) {
            const docs = snap.docs.map((docSnap) => {
              return { id: docSnap.id, ...docSnap.data() } as DraftUserDoc & { id: string };
            })
            setDraftUsers(docs);
          } else {
            setDraftUsers([]);
          }
        });
      } catch (error) {
        console.error('Error fetching comment docs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const getActiveState = async () => {
      try {
        if (!currentAuthUser) {
          console.error("User is not signed in.");
          // window.location.replace('/auth');
          return;
        }

        onSnapshot(doc(db, `draft/global/teams/${currentAuthUser.uid}`), (snap) => {
          if (snap.exists()) {
            setActiveState(snap.data()?.active);
          } else {
            setActiveState(false);
          }
        });
      } catch (error) {
        console.error('Error fetching comment docs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getDraftUsers();
    getActiveState();
  }, [currentAuthUser]);

  // Add click handler for the document
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      // If click is not on a draft user button, clear selection
      if (!target.closest('.draft-user-button')) {
        setSelectedUserId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Function to handle Draft Pick Submission
  const handleDraftPick = async (userId: string) => {
    setIsLoading(true);

    if (!currentAuthUser) {
      alert('Authenticationis missing.');
      return;
    }

    try {
      // Prepare data to send to draftUser function
      const draftUserFn = httpsCallable<
        {
          draftUserId: string;
          captainUserId: string;
        },
        DraftUserDataResponse
      >(functions, 'draftUser');
      const response = await draftUserFn({
        draftUserId: userId,
        captainUserId: `${currentAuthUser.uid}`
      });

      console.log(response.data.message);
      setSelectedUserId(null);
      setIsLoading(false);
      alert("User drafted successfully!");
    } catch (error) {
      console.error('Pick Error:', error);
      alert('Failed to submit draft pick.');
      setIsLoading(false);
    }
  };

  return (
    <div className='App w-screen h-screen fixed bg-light dark:bg-dark'>
      <ReactiveBGTexture />
      <div className='w-screen h-screen flex flex-col justify-start items-center bg-transparent fixed top-0 left-0'>
        <div className='w-full h-[10vh] 2xl:h-[7vh] flex flex-row justify-between items-center p-3 border-b-[1px] border-lightGray dark:border-darkGray'>
          <img className='h-full' src={challengeLogo} alt="March Madness" />
          {/* <h1 className='text-xl text-dark dark:text-light font-inter font-extralight uppercase'>Draft</h1> */}
        </div>
        <div className='w-full h-full max-h-[90vh] 2xl:max-h-[93vh] grid grid-cols-4 md:grid-cols-6 lg:grid-cols-7 2xl:grid-cols-10 gap-10 p-4 overflow-y-auto'>
          {draftUsers.map((user, index) => (
            <button 
              key={index}
              className={`w-full aspect-[3/4] flex flex-col items-center bg-lightGray border-[2px] border-dark dark:border-darkGray shadow-darkDouble dark:shadow-darkGrayDouble p-4 relative draft-user-button ${activeState && 'hover:border-c1 hover:shadow-c1Double transition-all duration-300'} ${selectedUserId === (user.id || String(index)) && 'border-c1 shadow-c1Double'}`}
              onClick={() => {
                if (selectedUserId === (user.id || String(index))) {
                  handleDraftPick(user.id);
                  console.log('User picked');
                } else {
                  setSelectedUserId(user.id || String(index));
                }
              }}
              disabled={!activeState}
            >
              {selectedUserId === (user.id || String(index)) && activeState && (
                <div className="absolute inset-0 z-30 bg-transparentLight backdrop-blur-md flex flex-col items-center justify-center">
                  <svg className='w-1/2 fill-c1 mb-6' viewBox="0 0 250 250">
                    <path d="M0 0h250v250H0z" className='fill-none'/>
                    <clipPath id="a">
                      <path d="M0 0h250v250H0z"/>
                    </clipPath>
                    <g clip-path="url(#a)">
                      <path 
                        d="M75.637 29.108c35.565 0 64.425-28.812 64.425-64.378 0-35.565-28.86-64.377-64.425-64.377-35.519 0-64.378 28.812-64.378 64.377 0 35.566 28.859 64.378 64.378 64.378Zm0-11.114c-29.449 0-53.239-23.815-53.239-53.264s23.79-53.264 53.239-53.264c29.449 0 53.264 23.815 53.264 53.264s-23.815 53.264-53.264 53.264ZM68.872-5.322c1.966 0 3.653-.975 4.873-2.762l29.181-45.544c.712-1.163 1.342-2.488 1.342-3.737 0-2.71-2.399-4.573-5.026-4.573-1.636 0-3.152.929-4.334 2.776L68.66-17.272 55.763-33.358c-1.377-1.753-2.69-2.3-4.345-2.3-2.662 0-4.826 2.134-4.826 4.889 0 1.32.531 2.567 1.432 3.769L63.741-8.053c1.51 1.884 3.075 2.731 5.131 2.731Z"
                        transform="matrix(1.94095 0 0 1.94166 -21.853 193.482)"
                      />
                    </g>
                  </svg>
                  <h1 className='text-xl text-c1 font-inter font-extrabold uppercase'>
                    Confirm Pick
                  </h1>
                </div>
              )}
              {/* Profile Picture Container */}
              <div className='w-full aspect-square mb-4'>
                {user.profilePic ? (
                  <img
                    src={user.profilePic}
                    alt={`${user.firstName} ${user.lastName}`}
                    className='w-full h-full object-cover bg-lightGray border-[1px] border-dark shadow-darkHalf'
                  />
                ) : (
                  <div className='w-full h-full flex justify-center items-center bg-lightGray border-[1px] border-dark shadow-darkHalf'>
                    <span className='text-6xl font-light text-dark'>
                      {user.firstName?.[0] || '?'}
                    </span>
                  </div>
                )}
              </div>

              {/* User Info */}
              <div className='w-full flex flex-col items-center text-center'>
                <h2 className='text-xl text-dark font-inter font-black uppercase tracking-wider'>
                  {user.firstName || 'Unknown'}
                </h2>
                <h3 className='text-lg text-dark font-inter font-bold uppercase tracking-wide mb-2'>
                  {user.lastName || ''}
                </h3>
                <p className='text-base text-dark font-inter font-medium'>
                  Points: {user.totalPoints || 0}
                </p>
              </div>
            </button>
          ))}
        </div>
        {isLoading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default Draft; 