import { useEffect, useRef, useState } from 'react';
import { db, functions } from '../FirebaseConfig';
import { collection, query, orderBy, limit, DocumentData, QueryDocumentSnapshot, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { User } from "firebase/auth";

// Interface Declarations
interface ActivityDoc {
    userId: string;
    displayName?: string;
    profilePic?: string | undefined;
    teamName?: string | null;
    teamId?: string | null;
    day: number;
    rawComponents: {
        meat: number;
        armor: number;
        resistanceMins: number;
        resistanceMeps: number;
        cardioMins: number;
        cardioMeps: number;
        hydration: number;
    };
    completedComponents: {
        meat: number;
        armor: number;
        resistance: number;
        cardio: number;
        hydration: number;
    };
    message?: string;
    commentCount?: number;
    likeCount?: number;
    likes?: {
        [key: string]: boolean;
    };
    engagementScore: number;
    loggedAt: string;
    id: string;
    expanded: boolean;
}
interface CommentDoc {
    userId: string;
    displayName: string;
    profilePic: string;
    loggedAt: string;
    message: string;
    id: string;
}

// Type declarations
type LogProps = {
  currentAuthUser: User | null;
  isMobile: boolean | null;
};

function Activity({ currentAuthUser, isMobile }: LogProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [activity, setActivity] = useState<ActivityDoc[]>([]);
    const [comments, setComments] = useState<CommentDoc[]>([]);
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const [lastExpanded, setLastExpanded] = useState<number | null>(null);
    const [commentInput, setCommentInput] = useState('');
    const [pageSize, setPageSize] = useState(20);

    // Will load 20 posts at a time.
    // ADJUST AS NEEDED.
    const PAGE_SIZE = 20;

    /**
     * Fetch the 'next' page of activity posts from Firebase, ordered by engagementScore (descending).
     * Standard pagination approach with 'startAfter()'.
     */

    const fetchComments = async (activityId: string) => {
        if (isLoading) return;
        setIsLoading(true);

        try {
            if (!currentAuthUser) {
                console.error("User is not signed in.");
                window.location.replace('/auth');
                return;
            }

            const baseQuery = query(
                collection(db, `activity/${activityId}/comments`),
                orderBy('loggedAt', 'desc'),
            );

            onSnapshot(baseQuery, (snap) => {
                if (!snap.empty) {
                    const docs = snap.docs.map((docSnap) => {
                        return { id: docSnap.id, ...docSnap.data() } as CommentDoc & { id: string };
                    })
                    setComments(docs);
                } else {
                    setComments([]);
                }
            });
        } catch (error) {
            console.error('Error fetching comment docs:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Initial load
    useEffect(() => {
        if (currentAuthUser) {
            if (isLoading) return;
            setIsLoading(true);
    
            try {
                if (!currentAuthUser) {
                    console.error("User is not signed in.");
                    window.location.replace('/auth');
                    return;
                }
    
                const baseQuery = query(
                    collection(db, 'activity'),
                    orderBy('engagementScore', 'desc'),
                    orderBy('loggedAt', 'desc'),
                    limit(pageSize)
                );
    
                // let pageQuery = baseQuery;
                // if (lastDoc) {
                //     pageQuery = query(baseQuery, startAfter(lastDoc));
                // }
    
                // EDIT THIS TO WORK!
                const unsubscribe = onSnapshot(baseQuery, (snap) => {
                    if (!snap.empty) {
                        setActivity((prev) =>
                            snap.docs.map((docSnap) => {
                                const expanded = prev.find((old) => old.id === docSnap.id)?.expanded || false;
                                return {
                                    id: docSnap.id,
                                    expanded,
                                    ...docSnap.data(),
                                } as ActivityDoc & { id: string; expanded: boolean };
                            })
                        );
                        // The last doc in this page is the new 'startAfter' for the next page
                        setLastDoc(snap.docs[snap.docs.length - 1]);
                    } else {
                        // No more docs to load
                        setHasMore(false);
                    }
                });

                return () => unsubscribe();
            } catch (error) {
                console.error('Error fetching activity docs:', error);
            } finally {
                setIsLoading(false);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAuthUser, pageSize, setActivity]);

    // When user scrolls near the bottom, fetch the next page automatically.
    const containerRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (!containerRef.current || isLoading || !hasMore) return;

        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        // If the user is near the bottom, load more.
        if (scrollTop + clientHeight >= scrollHeight - 20 && activity.length > 0) {
            // fetchNextPage();
            setPageSize((current) => current+20);
            console.log('Fetching next page.');
        }
    };

    async function handleLike(activityId: string, currentLikeStatus: boolean) {
        try {
            const logLikeFn = httpsCallable(functions, 'logLike');
            // If currentLikeStatus = true => user has already liked => new like = false (unlike)
            // If currentLikeStatus = false => user wants to like => new like = true (like)
            const response = await logLikeFn({ activityId, like: !currentLikeStatus });
            console.log('Log like response:', response.data);
            // Now refresh or update local state to reflect new like
            // fetchNextPage(); ???
        } catch (err) {
            console.error('Error liking post:', err);
        }
    };

    async function handleCreateComment(activityId: string, message: string) {
        const logCommentFn = httpsCallable(functions, 'logComment');
        await logCommentFn({
            activityId,
            action: 'create',
            message,
        });
    };

    async function handleDeleteComment(activityId: string, commentId: string) {
        const logCommentFn = httpsCallable(functions, 'logComment');
        await logCommentFn({
            activityId,
            action: 'delete',
            commentId,
        });
    };

    return (
        <div className={`Activity w-full h-[95%] overflow-y-auto bg-transparent ${isMobile ? 'col-span-3 2xl:col-span-4 mb-24' : 'max-h-[65vh] 2xl:max-h-[78vh]'}`}>
            <div className='w-full h-full flex flex-col justify-start items-center px-6'>
                {/* Header */}
                <h1 className='text-3xl text-dark dark:text-light font-inter font-black italic uppercase my-6'>
                    Activity
                </h1>
                {/* Feed */}
                <div className='w-full h-full flex flex-col justify-start items-center overflow-y-auto overflow-x-hidden mb-24 md:mb-8' ref={containerRef} onScroll={handleScroll}>
                    {activity.map((doc, index) => {
                        const timestampStr = new Date(doc.loggedAt).toLocaleString();

                        return (
                            <div key={index} className='w-full h-fit flex flex-col justify-start items-center border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull p-3 mb-6 bg-lightGray'>
                                {/* Top Row: Profile Pic + DisplayName + Team + Time */}
                                <div className={`w-full grid ${isMobile ? 'grid-cols-2' : 'grid-cols-3'} mb-3`}>
                                    <div className='justify-self-start flex flex-row justify-start items-start'>
                                        {doc.profilePic && (
                                            <img
                                                src={doc.profilePic}
                                                alt={`${doc.displayName || ''} Profile`}
                                                className='w-16 h-16 aspect-square bg-lightGray border-[1px] border-dark shadow-darkHalf mr-3'
                                            />
                                        )}
                                        <div className='flex flex-col justify-start items-start'>
                                            <h2 className='text-lg 2xl:text-xl text-dark font-inter font-extrabold'>
                                                {doc.displayName || ''}
                                            </h2>
                                            {doc.teamName && (
                                                <h2 className='text-base text-dark font-inter font-bold'>
                                                    {doc.teamName}
                                                </h2>
                                            )}
                                        </div>
                                    </div>
                                    {/* Components - IF DESKTOP */}
                                    {!isMobile && (
                                        <div className='justify-self-center flex flex-row justify-center item-center gap-3'>
                                            {doc.rawComponents.meat > 0 && (
                                                <div className='w-24 h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c1'>
                                                    <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.meat}g</h3>
                                                </div>
                                            )}
                                            {doc.rawComponents.armor > 0 && (
                                                <div className='w-24 h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c2'>
                                                    <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.armor} mins</h3>
                                                </div>
                                            )}
                                            {(doc.rawComponents.resistanceMeps > 0 && doc.rawComponents.resistanceMeps >= 100 && doc.rawComponents.resistanceMeps > doc.rawComponents.resistanceMins) || (doc.rawComponents.resistanceMeps > 0 && doc.rawComponents.resistanceMins === 0) ?
                                                <div className='w-24 h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c3'>
                                                    <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.resistanceMeps} MEPs</h3>
                                                </div>
                                            : null}
                                            {(doc.rawComponents.resistanceMins > 0 && doc.rawComponents.resistanceMeps < 100 && doc.rawComponents.resistanceMeps < doc.rawComponents.resistanceMins) || (doc.rawComponents.resistanceMins > 0 && doc.rawComponents.resistanceMeps === 0) ?
                                                <div className='w-24 h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c3'>
                                                    <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.resistanceMins} Mins</h3>
                                                </div>
                                            : null}
                                            {(doc.rawComponents.cardioMeps > 0 && doc.rawComponents.cardioMeps >= 100 && doc.rawComponents.cardioMeps > doc.rawComponents.cardioMins) || (doc.rawComponents.cardioMeps > 0 && doc.rawComponents.cardioMins === 0) ?
                                                <div className='w-24 h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c4'>
                                                    <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.cardioMeps} MEPs</h3>
                                                </div>
                                            : null}
                                            {(doc.rawComponents.cardioMins > 0 && doc.rawComponents.cardioMeps < 100 && doc.rawComponents.cardioMeps < doc.rawComponents.cardioMins) || (doc.rawComponents.cardioMins > 0 && doc.rawComponents.cardioMeps === 0) ?
                                                <div className='w-24 h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c4'>
                                                    <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.cardioMins} mins</h3>
                                                </div>
                                            : null}
                                            {doc.rawComponents.hydration > 0 && (
                                                <div className='w-24 h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c5'>
                                                    <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.hydration}oz</h3>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {/* <h3 className='justify-self-end text-base text-dark font-inter font-extrabold'>
                                        {timestampStr}
                                    </h3> */}
                                </div>
                                {/* Components - IF MOBILE */}
                                {isMobile && (
                                    <div className='w-full flex flex-row justify-center item-center gap-3 mb-3'>
                                        {doc.rawComponents.meat > 0 && (
                                            <div className='w-full max-w-[20%] h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c1'>
                                                <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.meat}g</h3>
                                            </div>
                                        )}
                                        {doc.rawComponents.armor > 0 && (
                                            <div className='w-full max-w-[20%] h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c2'>
                                                <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.armor} mins</h3>
                                            </div>
                                        )}
                                        {(doc.rawComponents.resistanceMeps > 0 && doc.rawComponents.resistanceMeps >= 100 && doc.rawComponents.resistanceMeps > doc.rawComponents.resistanceMins) || (doc.rawComponents.resistanceMeps > 0 && doc.rawComponents.resistanceMins === 0) ?
                                            <div className='w-full max-w-[20%] h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c3'>
                                                <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.resistanceMeps} MEPs</h3>
                                            </div>
                                        : null}
                                        {(doc.rawComponents.resistanceMins > 0 && doc.rawComponents.resistanceMeps < 100 && doc.rawComponents.resistanceMeps < doc.rawComponents.resistanceMins) || (doc.rawComponents.resistanceMins > 0 && doc.rawComponents.resistanceMeps === 0) ?
                                            <div className='w-full max-w-[20%] h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c3'>
                                                <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.resistanceMins} mins</h3>
                                            </div>
                                        : null}
                                        {(doc.rawComponents.cardioMeps > 0 && doc.rawComponents.cardioMeps >= 100 && doc.rawComponents.cardioMeps > doc.rawComponents.cardioMins) || (doc.rawComponents.cardioMeps > 0 && doc.rawComponents.cardioMins === 0) ?
                                            <div className='w-full max-w-[20%] h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c4'>
                                                <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.cardioMeps} MEPs</h3>
                                            </div>
                                        : null}
                                        {(doc.rawComponents.cardioMins > 0 && doc.rawComponents.cardioMeps < 100 && doc.rawComponents.cardioMeps < doc.rawComponents.cardioMins) || (doc.rawComponents.cardioMins > 0 && doc.rawComponents.cardioMeps === 0) ?
                                            <div className='w-full max-w-[20%] h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c4'>
                                                <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.cardioMins} mins</h3>
                                            </div>
                                        : null}
                                        {doc.rawComponents.hydration > 0 && (
                                            <div className='w-full max-w-[20%] h-10 flex justify-center items-center border-[1px] border-dark shadow-darkHalf bg-c5'>
                                                <h3 className='text-xs sm:text-sm md:text-base text-dark font-inter font-medium italic'>{doc.rawComponents.hydration}oz</h3>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {/* Message/Content */}
                                {doc.message && (
                                    <h4 className='w-full text-base text-dark font-inter font-normal mb-3'>
                                        {doc.message}
                                    </h4>
                                )}
                                {/* Attachments */}
                                {/* {doc.attachments && (
                                    <div className='w-full aspect-portraitImg mb-3'>
                                        <img
                                            src={doc.attachment}
                                            alt={`${doc.displayName || ''} Attachment`}
                                            className='h-full aspect-portraitImg rounded-xl bg-lightGray dark:bg-darkGray' />
                                    </div>
                                )} */}
                                {/* Footer: Likes/reactions + Comment Button */}
                                {/* <div className={`${isMobile ? 'w-full' : 'w-[25%]'} flex flex-row justify-between items-center`}> */}
                                <div className='w-full flex flex-row justify-between items-center'>
                                    <button className='flex flex-row justify-start items-center gap-1 group' onClick={() => {
                                        if (!currentAuthUser) return

                                        if (doc.likes) {
                                            if (currentAuthUser.uid in doc.likes) {
                                                handleLike(doc.id, true);
                                            } else {
                                                handleLike(doc.id, false);
                                            }
                                        } else {
                                            handleLike(doc.id, false);
                                        }
                                    }}>
                                        <svg
                                            className={`w-7 aspect-square fill-light group-hover:fill-orange ${currentAuthUser !== null && !currentAuthUser.uid && doc.likes ? currentAuthUser.uid in doc.likes ? 'fill-orange' : '' : ''}`}
                                            viewBox="0 0 500 500"
                                        >
                                            <path d="M0 0h500v500H0z" className='fill-none' />
                                            <path
                                                d="M80.747 26.52c1.926 0 4.723-1.574 7.22-3.235 34.647-22.592 57.442-49.24 57.442-76.145 0-24.023-16.725-40.427-37.079-40.427-12.657 0-22.089 7.012-27.583 17.284C75.29-86.237 65.82-93.287 53.164-93.287c-20.355 0-37.08 16.404-37.08 40.427 0 26.905 22.782 53.553 57.443 76.145 2.483 1.661 5.332 3.235 7.22 3.235Z"
                                                transform="translate(-62.184 379.069) scale(3.86623)"
                                                className='stroke-[8px] stroke-dark'
                                            />
                                        </svg>
                                        <h5 className={`text-sm text-dark font-inter font-light group-hover:font-normal ${currentAuthUser !== null && !currentAuthUser.uid && doc.likes ? currentAuthUser.uid in doc.likes ? 'font-normal' : '' : ''}`}>
                                            {doc.likeCount}
                                        </h5>
                                    </button>
                                    {/* DEBUGGING */}
                                    {/* <h6 className='text-sm text-dark mt-3'>
                                        DEBUGGING - Engagement Score: {doc.engagementScore}
                                    </h6> */}
                                    <button className='flex flex-row justify-start items-center gap-1 group' onClick={() => {
                                        setCommentInput('');
                                        if (lastExpanded === null) {
                                            activity[index].expanded = true;
                                            setLastExpanded(index);

                                            fetchComments(doc.id);
                                        } else if (lastExpanded === index) {
                                            activity[index].expanded = false;
                                            setLastExpanded(null);
                                        } else if (lastExpanded !== null && lastExpanded !== index) {
                                            activity[index].expanded = true;
                                            activity[lastExpanded].expanded = false;
                                            setLastExpanded(index);

                                            fetchComments(doc.id);
                                        }
                                    }}>
                                        <svg
                                            className={`w-7 aspect-square fill-light group-hover:fill-orange ${doc.expanded ? 'fill-orange' : ''}`}
                                            viewBox="0 0 500 500"
                                        >
                                            <path d="M0 0h500v500H0z" className='fill-none'/>
                                            <path
                                                d="M56.805 35.994c2.685 0 4.794-1.215 7.982-4.071l23.457-20.864h41.242c18.778 0 29.981-11.344 29.981-29.943v-50.174c0-18.599-11.203-29.981-29.981-29.981H46.065c-18.778 0-29.981 11.355-29.981 29.981v50.174c0 18.626 11.787 29.943 29.105 29.943h5.369v17.997c0 4.243 2.383 6.938 6.247 6.938Z"
                                                transform="translate(-56.087 359.925) scale(3.48716)"
                                                className='stroke-[8px] stroke-dark'
                                            />
                                        </svg>
                                        <h5 className={`text-sm text-dark font-inter font-light group-hover:font-normal ${doc.expanded ? 'font-normal' : ''}`}>
                                            {doc.commentCount !== 1 ? `${doc.commentCount} Comments` : `${doc.commentCount} Comment`}
                                        </h5>
                                    </button>
                                </div>

                                {doc.expanded && (
                                    <div className='w-full'>
                                        <hr className='my-3 border-darkGray'></hr>
                                        <div className='w-full flex flex-row justify-center items-center'>
                                            <input
                                                type="text"
                                                value={commentInput}
                                                onChange={(e) => {
                                                    setCommentInput(e.target.value);
                                                }}
                                                placeholder='Message'
                                                className="w-full text-xs sm:text-sm md:text-base text-dark font-inter font-medium p-2 placeholder:text-lightGray focus:outline-none"
                                            />
                                            <button className='w-24 h-10 border-[1px] border-dark shadow-darkHalf bg-c1 text-xs sm:text-sm md:text-base text-dark font-inter font-medium text-center ml-3' onClick={() => {
                                                handleCreateComment(doc.id, commentInput);
                                                setCommentInput('');
                                            }}>Send</button>
                                        </div>
                                        <hr className='my-3 border-darkGray'></hr>
                                        {comments.map((comment, index) => {
                                            return (
                                                <div className='w-full h-fit flex flex-col justify-start items-center p-3' key={index}>
                                                    <div className={`w-full flex flex-row justify-between items-center mb-3`}>
                                                        <div className='justify-self-start flex flex-row justify-start items-start'>
                                                            {comment.profilePic && (
                                                                <img
                                                                    src={comment.profilePic}
                                                                    alt={`${comment.displayName || ''} Profile`}
                                                                    className='w-16 h-16 aspect-square bg-lightGray border-[1px] border-dark shadow-darkHalf mr-3'
                                                                />
                                                            )}
                                                            <div className='flex flex-col justify-start items-start'>
                                                                <h2 className='text-lg 2xl:text-xl text-dark font-inter font-extrabold'>
                                                                    {comment.displayName || ''}
                                                                </h2>
                                                                <h2 className='text-base text-dark font-inter font-medium'>
                                                                    {comment.message}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <button className='min-w-24 h-10 border-[1px] border-dark shadow-darkHalf bg-c5 text-xs sm:text-sm md:text-base text-dark font-inter font-medium text-center ml-3' onClick={() => {
                                                            handleDeleteComment(doc.id, comment.id);
                                                        }}>Delete</button>
                                                    </div>
                                                    {comments.length !== index+1 && (
                                                        <hr className='w-full my-3 border-darkGray'></hr>
                                                    )}
                                                </div>
                                            )
                                        })}
                                        {!comments.length && (
                                            <h5 className='text-sm text-dark font-inter font-light group-hover:font-normal'>
                                                No Comments
                                            </h5>
                                        )}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                    {isLoading && <h6 className='w-full text-center text-sm text-dark dark:text-light font-inter font-light'>Loading...</h6>}
                    {!hasMore && <h6 className='w-full text-center text-sm text-dark dark:text-light font-inter font-light mb-28 md:mb-6'>No more posts.</h6>}
                </div>
            </div>
        </div>
    )
}

export default Activity;