import './Loader.css';

function Loader() {
    return (
        <div className='loaderDiv'>
            <div className='background' />
            <div className='wheel' />
        </div>
    );
}

export default Loader;