import { useEffect, useState } from 'react';
import { db } from '../FirebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';
import { User } from 'firebase/auth';
import Loader from '../Loader';

// Interface declarations
interface UserDoc {
    userId: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    email?: string;
    phone?: string;
    profilePic?: string;
    gender?: string;
    sfHousehold?: string;
    shirtSize?: string;
    staff?: boolean;
    spouse?: boolean;
    team?: string | null;
    acceptedDraft?: boolean | null;
    initialAuthType: string;
    proteinGoal?: number;
    weightGoal?: number;
    hydrationGoal?: number;
    points: {
        meatPoints: number;
        armorPoints: number;
        resistancePoints: number;
        cardioPoints: number;
        hydrationPoints: number;
        totalPoints: number;
    };
    stats: {
        totalMeat: number;
        totalArmor: number;
        totalResistanceMins: number;
        totalResistanceMeps: number;
        totalCardioMins: number;
        totalCardioMeps: number;
        totalHydration: number;
    };
}

// Type declarations
type LogProps = {
    currentAuthUser: User | null;
    isMobile: boolean | null;
  };

function Stats({ currentAuthUser, isMobile }: LogProps) {
    const [userData, setUserData] = useState<UserDoc | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (currentAuthUser) {
            setIsLoading(true);
    
            try {
                if (!currentAuthUser) {
                    console.error("User is not signed in.");
                    window.location.replace('/auth');
                    return;
                }
    
                onSnapshot(doc(db, `users/${currentAuthUser.uid}`), (snap) => {
                    if (snap.exists()) {
                        setUserData({ userId: snap.id, ...snap.data() } as UserDoc & { userId: string });
                    } else {
                        alert('Unable to retreive user data.');
                        setUserData(null);
                    }
                });
            } catch (error) {
                console.error('Error fetching activity docs:', error);
            } finally {
                setIsLoading(false);
            };
        };
    }, [currentAuthUser]);

    if (isLoading) return <Loader />;

    return (
        <div className={`Leaderboard w-full h-full bg-transparent ${isMobile ? 'col-span-3 2xl:col-span-4 pb-12' : 'max-h-[27vh] 2xl:max-h-[15vh]'}`}>
            <div className='w-full h-full flex flex-col justify-start items-center px-6'>
                {/* Header */}
                <h1 className='text-3xl text-dark dark:text-light font-inter font-black italic uppercase mt-6'>
                    Stats
                </h1>
                {/* Feed */}
                <div className={`w-full h-full grid grid-cols-1 gap-6 mt-4 pb-1 ${isMobile ? 'mb-24 md:mb-8' : 'grid-cols-6'} overflow-y-auto`}>
                    <div key="meat" className="w-full h-full py-2 px-3 mb-6 flex flex-col justify-between items-start bg-c1 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                        <h2 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic uppercase">Meat</h2>
                        <h5 className="w-full text-center text-base font-inter font-medium">{userData?.points?.meatPoints || 0} Points</h5>
                        <hr className='w-full h-[1px] bg-dark border-[1px] border-dark' />
                        <h5 className="w-full text-center text-sm 2xl:text-base font-inter font-medium">{userData?.stats?.totalMeat || 0} grams</h5>
                    </div>
                    <div key="armor" className="w-full h-full py-2 px-3 flex flex-col justify-between items-start bg-c2 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                        <h2 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic uppercase">Armor</h2>
                        <h5 className="w-full text-center text-base font-inter font-medium">{userData?.points?.armorPoints || 0} Points</h5>
                        <hr className='w-full h-[1px] bg-dark border-[1px] border-dark' />
                        <h5 className="w-full text-center text-sm 2xl:text-base font-inter font-medium">{userData?.stats?.totalArmor || 0} mins</h5>
                    </div>
                    <div key="resistance" className="w-full h-full py-2 px-3 flex flex-col justify-between items-start bg-c3 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                        <h2 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic uppercase">Resistance</h2>
                        <h5 className="w-full text-center text-base font-inter font-medium">{userData?.points?.resistancePoints || 0} Points</h5>
                        <hr className='w-full h-[1px] bg-dark border-[1px] border-dark' />
                        <h5 className="w-full text-center text-sm 2xl:text-base font-inter font-medium">{userData?.stats?.totalResistanceMins || 0} mins / {userData?.stats?.totalResistanceMeps || 0} MEPs</h5>
                    </div>
                    <div key="cardio" className="w-full h-full py-2 px-3 flex flex-col justify-between items-start bg-c4 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                        <h2 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic uppercase">Cardio</h2>
                        <h5 className="w-full text-center text-base font-inter font-medium">{userData?.points?.cardioPoints || 0} Points</h5>
                        <hr className='w-full h-[1px] bg-dark border-[1px] border-dark' />
                        <h5 className="w-full text-center text-sm 2xl:text-base font-inter font-medium">{userData?.stats?.totalCardioMins || 0} mins / {userData?.stats?.totalCardioMeps || 0} MEPs</h5>
                    </div>
                    <div key="hydration" className="w-full h-full py-2 px-3 flex flex-col justify-between items-start bg-c5 border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                        <h2 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic uppercase">Hydration</h2>
                        <h5 className="w-full text-center text-base font-inter font-medium">{userData?.points?.hydrationPoints || 0} Points</h5>
                        <hr className='w-full h-[1px] bg-dark border-[1px] border-dark' />
                        <h5 className="w-full text-center text-sm 2xl:text-base font-inter font-medium">{userData?.stats?.totalHydration || 0} oz</h5>
                    </div>
                    <div key="total" className="w-full h-full py-2 px-3 flex flex-col justify-between items-start bg-lightGray border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull">
                        <h2 className="text-lg 2xl:text-xl text-dark font-inter font-extrabold italic uppercase">Total</h2>
                        <h5 className="w-full text-center text-base font-inter font-medium">{userData?.points?.totalPoints || 0} Points</h5>
                        <hr className='w-full h-[1px] bg-dark border-[1px] border-dark' />
                        <h5 className="w-full text-center text-sm 2xl:text-base font-inter font-medium">{(userData?.points?.totalPoints || 0) >= 155 ? 'Perfect score at 180 Points' : (userData?.points?.totalPoints || 0) >= 125 ? 'Enter raffle at 155 Points' : 'Unlock Pt 2 at 125 Points'}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats;