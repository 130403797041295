import challengeLogo from '../assets/Logo.svg';

function FAQs() {
    return(
        <div className='w-screen overflow-y-auto min-h-screen flex flex-col justify-start items-center text-center bg-light'>
            <div className='w-[95%] md:max-w-[75%] lg:max-w-[50%] my-12 flex flex-col justify-center items-center text-[#202020]'>
                <img className='w-3/4 md:w-1/2 lg:w-1/4 m-auto mb-8' src={challengeLogo} alt="March Madness" />

                <h1 className='w-full mb-4 text-2xl font-inter font-black text-center'>FAQ (Frequently Asked Questions)</h1>

                <div className='w-full mb-4 text-left'>
                    <h2 className='text-xl font-inter font-bold uppercase'>General</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li><b>How do I earn points?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Each day, complete the required habits for each category (M, A, R, C, H).</li>
                            <li>You'll earn 1 point for each habit completed daily.</li>
                        </ul>
                        <li><b>How do I qualify for Challenge 2?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Complete Challenge 1 by earning at least 125 points to qualify.</li>
                            <li>If you earn 155 points, you will be entered into a prize/gift card raffle.</li>
                        </ul>
                        <li><b>What happens after the Individual Challenge?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Once you complete Challenge 1, you'll receive an invite to participate in Challenge 2 (Team Competition).</li>
                            <li>Teams will be formed through a Live Team Draft on March 11th.</li>
                        </ul>
                        <li><b>How are the team competitions structured?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>The team competition will follow a head-to-head bracket style format.</li>
                            <li>Teams advance by accumulating the highest team scores over 3-4 weeks of weekly workouts.</li>
                        </ul>
                        <li><b>Can I be on the same team as my spouse?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Yes! You can choose to be drafted together or separately during the Team Draft on March 11th.</li>
                        </ul>
                        <li><b>What do I get for participating in Challenge 2?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>All participants will receive a March Madness Team Challenge shirt.</li>
                            <li>Improved health, some team pride, camaraderie, and fun competition as well as the chance to win some pretty good gift cards!</li>
                        </ul>
                        <li><b>Can I log both resistance and cardio on the same day?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>You can log both, but they must be done as separate workouts.</li>
                            <li>You cannot log both resistance and cardio for the same 30-45 minute workout session.</li>
                        </ul>
                        <li><b>What if I don't want to follow the nutrition? </b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>You don't have to if you don't want to see results!</li>
                        </ul>
                        <li><b>What if I miss a day or a habit?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>The challenge is designed to encourage consistency. If you miss a habit for the day, you can't earn the point for that day, but don't be discouraged—focus on getting back on track the next day!</li>
                        </ul>
                        <li><b>What happens if I don't meet the points target?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>The goal is to consistently earn points throughout the challenge. If you don't hit the points target for Challenge 1, you won't complete the challenge or qualify for the team competition. However, you can still continue to practice habits. </li>
                        </ul>
                    </ul>
                    <hr className='m-6 border-lightGray' />
                    <h2 className='text-xl font-inter font-bold uppercase'>MEAT (Protein-Focused Meals)</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li><b>What qualifies as a protein-focused meal?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>A protein-focused meal should include a good source of protein such as meat, poultry, fish, eggs, or plant-based protein like beans, tofu, or tempeh.</li>
                            <li>You need to consume at least 0.7 grams of protein per pound of body weight (or goal weight) daily. </li>
                        </ul>
                        <li><b>What if I'm struggling to meet my protein goals?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Feel free to reach out to <span className='underline cursor-pointer text-orange' onClick={() => window.open('mailto:bbuhler@elevationchurch.org', '_blank')}>Buck</span> for help with meal planning or protein recommendations. </li>
                        </ul>
                    </ul>
                    <hr className='m-6' />
                    <h2 className='text-xl font-inter font-bold uppercase'>ARMOR (Spiritual Growth - Armor of God)</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li><b>What counts as "Bible study or devotion time"?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Any time spent in the Word of God, whether reading scripture or following a devotional plan counts toward this requirement.</li>
                            <li>The goal is at least 20 minutes of focused, uninterrupted Bible study/devotion time.</li>
                        </ul>
                        <li><b>Is there a Bible Study I can follow?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Just as we need physical strength, we also need spiritual strength to face the challenges of life. You can do your own individual study or if you need help getting started you can join the 8 week staff study of Romans with LB which starts January 9th. CLICK <span className='underline cursor-pointer text-orange' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSffWcnsB5fR8QuXOSg50AJc3rubMT0WoYdF2yDio-uTV8aSxg/viewform', '_blank')}>HERE</span> to sign up.</li>
                        </ul>
                        <li><b>Can I combine my Bible study with other habits?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>While it's great to combine spiritual time with other activities, for this challenge, you should aim to complete the 20 minutes of Bible study as a separate habit.</li>
                            <li>So listening to worship music while driving to work or while working out does NOT count.</li>
                        </ul>
                    </ul>
                    <hr className='m-6' />
                    <h2 className='text-xl font-inter font-bold uppercase'>RESISTANCE (Strength Training)</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li><b>What counts as strength training?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Strength training includes exercises such as weight lifting, bodyweight exercises (e.g., push-ups, squats), or resistance band exercises.</li>
                            <li>Aim for 30-45 minutes of challenging weight training or complete 100 Myzone MEPS during your session.</li>
                        </ul>
                        <li><b>Can I combine my strength training with cardio for the same workout?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>No. If you're logging both, they must be separate workouts. You cannot log resistance and cardio for the same 30-45 minute workout.</li>
                        </ul>
                    </ul>
                    <hr className='m-6' />
                    <h2 className='text-xl font-inter font-bold uppercase'>CARDIO (Heart Health)</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li><b>What types of exercises count for cardio?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Challenging activity that raises your heart rate consistently and gets you moving for 30-45 minutes counts as cardio.</li>
                            <li>This includes activities like running, cycling, rowing, brisk walking, swimming, or any other sustained, high-intensity cardio exercise.</li>
                        </ul>
                        <li><b>Can I log both cardio and resistance training on the same day?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Yes, but remember, each should be a separate workout. You cannot log both for the same 30-45 minute session.</li>
                        </ul>
                    </ul>
                    <hr className='m-6' />
                    <h2 className='text-xl font-inter font-bold uppercase'>HYDRATION</h2>
                    <ul className='ml-6 md:ml-12 mb-4 text-base font-inter font-normal list-disc'>
                        <li><b>How much water should I drink each day?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Males: Drink at least 124 oz of water daily.</li>
                            <li>Females: Drink at least 92 oz of water daily.</li>
                            <li>These amounts can vary depending on individual needs, but this is the baseline for the challenge.</li>
                        </ul>
                        <li><b>What counts toward my daily hydration goal?</b></li>
                        <ul className='ml-6 md:ml-12 list-disc'>
                            <li>Only water, or drinks primarily consisting of water, count toward your daily hydration total.</li>
                            <li>This includes plain water, flavored water (without added sugar), and water-based drinks like sugar free herbal teas.</li>
                            <li>Beverages like coffee, sweet tea, soda, or alcohol do not count toward your hydration goal.</li>
                            <li>Water should remain your primary source of hydration.</li>
                        </ul>
                    </ul>
                </div>
                <h2 className='mb-8 text-base font-inter font-bold'>Contact <span className='underline cursor-pointer text-orange' onClick={() => window.open('mailto:bbuhler@elevationchurch.org', '_blank')}>Buck Buhler</span> if you have any questions or need assistance.</h2>
            </div>
        </div>
    )
}

export default FAQs;