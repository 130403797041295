import { useEffect, useState } from 'react';
import { db } from '../FirebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { User } from 'firebase/auth';
import Loader from '../Loader';

// Interface declarations
interface Participant {
    uid: string;
    displayName: string;
    profilePic: string;
    totalPoints: number;
    rank: number;
  }

// Type declarations
type LogProps = {
    currentAuthUser: User | null;
  };

function Leaderboard({ currentAuthUser }: LogProps) {
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchLeaderboardData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                if (!currentAuthUser) {
                    console.error("User is not signed in.");
                    window.location.replace('/auth');
                    return;
                }

                // 1) Get ALL user docs
                const usersSnap = await getDocs(collection(db, 'users'));
                const results = usersSnap.docs.map((docSnap) => {
                    const data = docSnap.data() || {};
                    const points = data.points || {};
                    return {
                        uid: docSnap.id,
                        displayName: data.displayName || `${data.firstName} ${data.lastName}` || 'No Name',
                        profilePic: data.profilePic || '',
                        totalPoints: points.totalPoints || 0,
                        rank: 0,
                    };
                });

                // 3) Sort by totalPoints DESC
                results.sort((a, b) => b.totalPoints - a.totalPoints);

                // 4) Assign ranks with 'competition style' ranking
                let currentRank = 1;
                for (let i = 0; i < results.length; i++) {
                    if (i === 0) {
                        // First participant is always rank 1
                        results[i].rank = currentRank;
                    } else {
                        // Compare current user points to previous user
                        if (results[i].totalPoints === results[i - 1].totalPoints) {
                            // Same rank as the previous participant
                            results[i].rank = results[i - 1].rank;
                        } else {
                            /**
                             * Rank = (index + 1)
                             * But if there was a tie above, we skip those ranks
                             * e.g. if two people had rank 1, the next person is rank 3
                             */
                            results[i].rank = i + 1;
                        }
                    }
                }

                setParticipants(results);
            } catch (err: any) {
                console.error('Error fetching leaderboard data:', err);
                setError('Failed to load leaderboard');
            } finally {
                setIsLoading(false);
            }
        };

        fetchLeaderboardData();
    }, [currentAuthUser]);

    if (isLoading) return <Loader />;
    if (error) return <p>{error}</p>;

    return (
        <div className='Leaderboard w-full h-full max-h-[90vh] 2xl:max-h-[93vh] bg-transparent col-span-3 2xl:col-span-4 border-lightGray dark:border-darkGray border-r-[1px]'>
            <div className='w-full h-full flex flex-col justify-start items-center px-6'>
                {/* Header */}
                <h1 className='text-3xl text-dark dark:text-light font-inter font-black italic uppercase mt-6'>
                    Leaderboard
                </h1>
                {/* Feed */}
                <div className='w-full h-full grid grid-cols-1 md:grid-cols-2 overflow-y-auto overflow-x-hidden md:px-4 pr-6 md:gap-4 mt-4 mb-24 md:mb-8'>
                    {participants.map((user) => (
                        <div key={user.uid} className='w-full h-28 flex flex-row justify-center items-center m-2 p-4 bg-lightGray border-[1px] border-dark dark:border-darkGray shadow-darkFull dark:shadow-darkGrayFull'>
                            {user.profilePic &&
                                <img
                                    src={user.profilePic}
                                    className='w-20 h-20 aspect-square bg-lightGray border-[1px] border-dark shadow-darkHalf mr-6'
                                />
                            }
                            <div className='flex flex-col justify-center items-center'>
                                <h1 className='uppercase text-center text-xl md:text-2xl 2xl:text-3xl text-dark font-inter font-black tracking-widest'>
                                    {user.rank}. {user.displayName}
                                </h1>
                                <h1 className='text-center text-base md:text-lg 2xl:text-xl text-dark font-inter font-semibold tracking-widest'>
                                    Points: {user.totalPoints}
                                </h1>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Leaderboard;