import { useEffect } from "react";
import { auth } from "../FirebaseConfig";
import Loader from "../Loader";

function SignOut() {
    useEffect(() => {
        const logOut = async () => {
            await auth.signOut();
            window.location.replace('/auth');
        };

        logOut();
    }, []);

    return (
        <Loader />
    )
}

export default SignOut;