import { useEffect, useState } from 'react';
import { db, auth, functions } from '../FirebaseConfig';
import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import Loader from '../Loader';
import ReactiveBGTexture from '../assets/ReactiveBGTexture';
import challengeLogo from '../assets/Logo.svg';

// Interface Declarations
interface DraftUserDoc {
  email: string;
  firstName: string;
  lastname: string;
  profilePic: string;
  spouse: boolean | null;
  staff: boolean | null;
  totalPoints: number | null;
  id: string;
}

function DraftAdmin() {
  // Global states
  const [isLoading, setIsLoading] = useState(true);
  const [currentAuthUser, setCurrentAuthUser] = useState<any>(null);
  const [latest, setLatest] = useState<any>([]);

  // Check registration status through Firebase Function
  const handleRegistrationCheck = async (user: any) => {
    try {
      const registrationCheckFn = httpsCallable<
        void,
        CheckRegistrationResponse
      >(functions, 'registrationCheck');
      const result = await registrationCheckFn();
      return result;
    } catch (error) {
      console.error('Error checking registration:', error);
      return null;
    }
  };

  const initializeAuthFlow = async (user: any) => {
    setIsLoading(true);

    try {
      // Check registration first
      const registered = await handleRegistrationCheck(user);
      if (!registered || !registered.data.registered) {
        window.location.replace('/auth');
        return;
      }

      if (!registered.data.data?.admin) {
        window.location.replace('/auth');
        return;
      }

      setCurrentAuthUser(user);
    } catch (error) {
      console.error('Error in auth flow:', error);
      window.location.replace('/auth');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log('User signed in:', user);
        await initializeAuthFlow(user);
      } else {
        console.log('No user signed in.');
        setCurrentAuthUser(null);
        setIsLoading(false);
        window.location.replace('/auth');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const getLatest = async () => {
      try {
        if (!currentAuthUser) {
          console.error("User is not signed in.");
          // window.location.replace('/auth');
          return;
        }

        onSnapshot(doc(db, 'draft/global'), (snap) => {
          setLatest(snap.data());
      });
      } catch (error) {
        console.error('Error fetching comment docs:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getLatest();
  }, [currentAuthUser]);

  const teamClicked = async (team: number) => {
    const teamRef = query(collection(db, 'draft/global/teams'), where('team', '==', team));
    const teamSnap = await getDocs(teamRef);
    if (teamSnap.size >= 2) {
      alert('More than 2 teams found!!!');
      return
    }
    teamSnap.forEach(async (doc) => {
      await setDoc(doc.ref, { active: true }, { merge: true });
    });
  };

  const stopClicked = async () => {
    const teamRef = collection(db, 'draft/global/teams');
    const teamSnap = await getDocs(teamRef);
    teamSnap.forEach(async (doc) => {
      await setDoc(doc.ref, { active: false }, { merge: true });
    });
  };

  return (
    <div className='App w-screen h-screen fixed bg-light dark:bg-dark'>
      <ReactiveBGTexture />
      <div className='w-screen h-screen flex flex-col justify-start items-center bg-transparent fixed top-0 left-0'>
        <div className='w-full h-full flex flex-col justify-center items-center overflow-y-auto'>
          <div className='w-full h-[85vh] grid grid-cols-3 gap-16 p-10'>
            <button className='w-full h-24 bg-lightGray border-[1px] border-dark shadow-darkFull text-xl text-dark font-inter font-extrabold uppercase' onClick={() => teamClicked(1)}>Angel</button>
            <button className='w-full h-24 bg-lightGray border-[1px] border-dark shadow-darkFull text-xl text-dark font-inter font-extrabold uppercase' onClick={() => teamClicked(2)}>Tim</button>
            <button className='w-full h-24 bg-lightGray border-[1px] border-dark shadow-darkFull text-xl text-dark font-inter font-extrabold uppercase' onClick={() => teamClicked(3)}>Genie</button>
            <button className='w-full h-24 bg-lightGray border-[1px] border-dark shadow-darkFull text-xl text-dark font-inter font-extrabold uppercase' onClick={() => teamClicked(4)}>Sarah</button>
            <button className='w-full h-24 bg-lightGray border-[1px] border-dark shadow-darkFull text-xl text-dark font-inter font-extrabold uppercase' onClick={() => teamClicked(5)}>Chris</button>
            <button className='w-full h-24 bg-lightGray border-[1px] border-dark shadow-darkFull text-xl text-dark font-inter font-extrabold uppercase' onClick={() => teamClicked(6)}>Jordan</button>
          </div>
          <div className='w-[90%] h-[1px] bg-dark' />
          <div className='w-full h-[15vh] flex flex-row justify-around items-center'>
            <h1 className='text-xl text-dark font-inter font-extrabold uppercase'>Team: {latest.lastTeam}</h1>
            <h1 className='text-xl text-dark font-inter font-extrabold uppercase'>User: {latest.lastUser}</h1>
            <button className='w-48 h-24 bg-c1 border-[1px] border-dark shadow-darkFull text-xl text-light font-inter font-extrabold uppercase' onClick={() => stopClicked()}>STOP</button>
          </div>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
    </div>
  );
}

export default DraftAdmin; 